<template>
	<aside class="sidebar" :class="{ 'sidebar--closed': sidebarState }">
		<div class="sidebar__wrapper">
			<div class="sidebar__header">
				<router-link to="/" class="logo">
					<img class="logo__img" src="@/assets/img/logo-icon.svg" alt="Enismaro">
					<img class="logo__text logo__img__text" src="@/assets/img/logo-text.svg" alt="Enismaro">
				</router-link>

				<button type="button" class="sidebar__toggler" @click="toggleSidebar">
					<inline-svg :src="require(`@/assets/img/ico-scroll-left.svg`)"/>
				</button>
			</div>
			<div class="sidebar__opener">
				<button type="button" class="sidebar__toggler" @click="toggleSidebar">
					<inline-svg :src="require(`@/assets/img/ico-scroll-right.svg`)"/>
				</button>
			</div>
			<div class="sidebar__group" v-for="nav in navigation[grantsLvl]" :key="nav.cat">
				<a href="#sidebar-item-01" class="sidebar__title" data-toggle="collapse" @click="toggleCategory">{{ nav.cat }}</a>
				<div id="sidebar-item-01" class="sidebar__body collapse show">
					<ul class="sidebar__list">
						<li class="sidebar__item tutorial_tooltip_bottom_left" :class="link.class ? link.class : ''" v-for="link in nav.links" :key="link.path">
							<router-link :to="link.path" class="sidebar__link">
								<icon-navbar :path="link.icon"></icon-navbar>
								<span class="sidebar__text">{{ link.title }}</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</aside>
</template>

<script>
import navigation from '@/config/navigation';
import Icons from '@/components/icons';
import { billingPlans } from "@/config/index.js";

export default {
	props: ['sidebarState'],
	components: {
		...Icons
	},
	data() {
		return {
			navigation,
			billingPlans,
		}
	},
	computed: {
		authData() {
			return this.$store.state.authData
		},
		userData() {
			return this.$store.state.userData
		},
		grantsLvl(){
			return 'ADMIN';
			// return this.userData.stripe_customer_id === null ? 'BASIC' : 'ADMIN';
		}
	},
	methods: {
		toggleSidebar() {
			this.$emit('toggle')
		},
		toggleCategory(ev) {
			ev.preventDefault();
			const target = ev.target;
			const sibling = target.nextElementSibling;
			target.classList.toggle('collapsed');
			sibling.classList.toggle('show');
		}
	}
}
</script>