<template>
    <div class="dropdown-wrapper" v-click-outside="closeMe">
        <button  
            class="dropdown-btn pagination__show-btn" 
            :class="{ active: open }" 
            @click="toggleMe"
        >
            <span class="active-categories">{{ value || '-' }}</span>
            <svg fill="none" viewBox="0 0 24 24" height="24" width="24">
				<path d="M8 10L12 14L16 10" stroke="#397490" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
			</svg>
        </button>

        <div class="dropdown-content categories__dropdown" :class="{ active: open }">
            <ul>
                <li v-for="option in options" :key="option" @click="change(option)">
                    <span>{{ option }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropdownSimple',
    props: ['options', 'value'],
    data() {
        return {
            open: false,
        }
    },
    methods: {
        change(option) {
            this.$emit('input', option);
            this.closeMe();
        },
        closeMe() {
            this.open = false
        },
        toggleMe() {
            this.open = !this.open
        }
    }
}
</script>