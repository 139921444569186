<template>
    <div class="pagination wizard-actions fixed">
        <div class="pagination__body">
            <button type="button" @click="cancel" class="btn btn-w-shadow btn-standart-2" v-if=" (!previous)||(previous && activeTab===pageTabs[0])">
                {{ $translate('cancel') }}
            </button>
            
            <button type="button" class="btn btn-w-shadow btn-standart-2" v-if="previous&& activeTab!==pageTabs[0]" @click="swipeTab(-1)">
                <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)"/>
                {{ $translate('previous') }}
            </button>
            
            <button type="button" class="btn btn-primary btn-standart icon-rigth" v-if="confirmName&& (activeTab===pageTabs[pageTabs.length-1]||offNextButton)" @click="confirm" :class={processing}>
                {{confirmName}}
            </button>

            <button type="button" class="btn btn-primary btn-standart icon-rigth" v-if="!offNextButton && activeTab !== pageTabs[pageTabs.length-1]" @click="swipeTab(1) ">
                {{ $translate('next') }}
                <inline-svg :src="require(`@/assets/img/Arrow-next.svg`)"/>
            </button>
        </div>
    </div>
</template>

<script>
export default {
	name: 'FooterTabsControl',
    //cancel - string route, previous-boolean, confirmName-string name, confirm-event confirm, swipeTab-event swipe type, pageTabs
	props: ['cancelRoute','previous','confirmName','pageTabs','activeTab','processing','offNextButton'],
    methods:{
        confirm(){
            this.$emit('confirm')
        },
        swipeTab(direction){
            this.$emit('swipeTab',direction)
        },
        cancel(){
            if(this.$listeners.cancelAction){
                this.$emit("cancelAction")
            }
            if(this.cancelRoute){

                this.$router.push(this.cancelRoute)
            }
        }
    }
}
</script>
