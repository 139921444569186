<template>
	<div class="popup_wrpr" data-target="confirm-password">
		<div class="popup_overlay" @click="closeMe"></div>
		<div class="popup__window">
			<div class="popup__window-content w-450">
				<div class="popup__window-header justify-right">
					<button type="button" class="popup__closer" @click="closeMe">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M15 5L5 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
							<path d="M5 5L15 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
						</svg>
					</button>
				</div>
				<div class="popup__window-body">
					<div class="icon" :class="`icon-${details.status.toLowerCase()}`">
						<svg width="62" height="62" viewBox="0 0 62 62" fill="none">
							<circle cx="30.9986" cy="30.9984" r="22.5094" stroke="#26A7E1" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></circle>

							<path class="success" d="M22.106 31.8444L27.5257 37.2642L27.4907 37.2292L39.7183 25.0016" stroke="#26A7E1" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path>

							<path class="error" d="M24.0401 38L24 37.96L38 24" stroke="#26A7E1" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path class="error" d="M24 24.0401L24.04 24L38 38" stroke="#26A7E1" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>

							<path class="warning" d="M31 44L31 44L31 44" stroke="#26A7E1" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
							<path class="warning" d="M30.9717 18.0283L31.0283 18.0283L31 37.799" stroke="#26A7E1" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
					<h3>{{ details.message }}</h3>
					<button type="button" class="btn btn-primary btn-standart-2" @click="closeMe">{{ $translate('got-it') }}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NotificationPopup',
	computed: {
		details() {
			return this.$store.state.ui.notificationPopup
		}
	},
	methods: {
		closeMe() {
			const value = { show: false, message: '', status: 'SUCCESS' }
			this.$store.commit('SET_UI', { field: 'notificationPopup', value })
		}
	}
}
</script>

<style scoped>
.icon svg .error,
.icon svg .success,
.icon svg .info,
.icon svg .warning {
	display: none;
}

.icon-error svg .error,
.icon-success svg .success,
.icon-info svg .info,
.icon-warning svg .warning {
	display: block;
}

.icon-error {
	color: var(--graph-red);
}
.icon-success {
	color: var(--accent-primary);
}
.icon-info {
	color: var(--graph-green);
}
.icon-warning {
	color: var(--graph-yellow);
}
.icon-error svg *,
.icon-error svg *,
.icon-success svg *,
.icon-info svg *,
.icon-warning svg * {
	stroke: currentColor;
}


</style>