/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePlan = /* GraphQL */ `
  subscription OnCreatePlan {
    onCreatePlan {
      id
      stripe_id
      title
      is_custom
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePlan = /* GraphQL */ `
  subscription OnUpdatePlan {
    onUpdatePlan {
      id
      stripe_id
      title
      is_custom
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePlan = /* GraphQL */ `
  subscription OnDeletePlan {
    onDeletePlan {
      id
      stripe_id
      title
      is_custom
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGlobalSettings = /* GraphQL */ `
  subscription OnCreateGlobalSettings {
    onCreateGlobalSettings {
      id
      stripe_public_key
      stripe_secret_key
      stripe_sandbox_publick_key
      stripe_sandbox_secret_key
      stripe_sandbox
      google_maps_api_key
      position_stack_api_key
      centaur_username
      centaur_password
      xfarm_username
      xfarm_password
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGlobalSettings = /* GraphQL */ `
  subscription OnUpdateGlobalSettings {
    onUpdateGlobalSettings {
      id
      stripe_public_key
      stripe_secret_key
      stripe_sandbox_publick_key
      stripe_sandbox_secret_key
      stripe_sandbox
      google_maps_api_key
      position_stack_api_key
      centaur_username
      centaur_password
      xfarm_username
      xfarm_password
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGlobalSettings = /* GraphQL */ `
  subscription OnDeleteGlobalSettings {
    onDeleteGlobalSettings {
      id
      stripe_public_key
      stripe_secret_key
      stripe_sandbox_publick_key
      stripe_sandbox_secret_key
      stripe_sandbox
      google_maps_api_key
      position_stack_api_key
      centaur_username
      centaur_password
      xfarm_username
      xfarm_password
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      fullname
      username
      profile_photo
      role
      grants
      location {
        lat
        lng
        address
      }
      email
      period_end
      period_start
      status
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatches {
        items {
          id
          dispatchID
          status
          ownerID
          owner {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companies {
            nextToken
          }
          incomingCompanies
          companiesStatus {
            id
            type
            status
            dispatchModifiedID
            pending_date
            approved_date
            delivery_date
            incoming_date
            reject_date
            return_date
            cancel_date
            delivered_date
            receive_date
          }
          documents {
            key
            title
          }
          batches {
            id
            name
            products
          }
          contract_conditions {
            condition_type
            select_batch_id
            select_device
            parameter_obeserved
            operator
            value
            type
            event_to_be_monitored
            custom_event
            from_date
            from_time
            to_date
            to_time
            date
            time
            description_of_certifications_required
            description_of_the_business_rule
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      teamID
      api_pofiles {
        name
        fields {
          username
          password
        }
      }
      max_team_members
      stripe_customer_id
      team_limit
      subscription_plan_id
      temporary_password
      createdAt
      updatedAt
      userCompanyId
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      fullname
      username
      profile_photo
      role
      grants
      location {
        lat
        lng
        address
      }
      email
      period_end
      period_start
      status
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatches {
        items {
          id
          dispatchID
          status
          ownerID
          owner {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companies {
            nextToken
          }
          incomingCompanies
          companiesStatus {
            id
            type
            status
            dispatchModifiedID
            pending_date
            approved_date
            delivery_date
            incoming_date
            reject_date
            return_date
            cancel_date
            delivered_date
            receive_date
          }
          documents {
            key
            title
          }
          batches {
            id
            name
            products
          }
          contract_conditions {
            condition_type
            select_batch_id
            select_device
            parameter_obeserved
            operator
            value
            type
            event_to_be_monitored
            custom_event
            from_date
            from_time
            to_date
            to_time
            date
            time
            description_of_certifications_required
            description_of_the_business_rule
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      teamID
      api_pofiles {
        name
        fields {
          username
          password
        }
      }
      max_team_members
      stripe_customer_id
      team_limit
      subscription_plan_id
      temporary_password
      createdAt
      updatedAt
      userCompanyId
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      fullname
      username
      profile_photo
      role
      grants
      location {
        lat
        lng
        address
      }
      email
      period_end
      period_start
      status
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatches {
        items {
          id
          dispatchID
          status
          ownerID
          owner {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companies {
            nextToken
          }
          incomingCompanies
          companiesStatus {
            id
            type
            status
            dispatchModifiedID
            pending_date
            approved_date
            delivery_date
            incoming_date
            reject_date
            return_date
            cancel_date
            delivered_date
            receive_date
          }
          documents {
            key
            title
          }
          batches {
            id
            name
            products
          }
          contract_conditions {
            condition_type
            select_batch_id
            select_device
            parameter_obeserved
            operator
            value
            type
            event_to_be_monitored
            custom_event
            from_date
            from_time
            to_date
            to_time
            date
            time
            description_of_certifications_required
            description_of_the_business_rule
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      teamID
      api_pofiles {
        name
        fields {
          username
          password
        }
      }
      max_team_members
      stripe_customer_id
      team_limit
      subscription_plan_id
      temporary_password
      createdAt
      updatedAt
      userCompanyId
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      company_name
      year_of_foundation
      history_of_company
      website
      production_per_year
      production_area
      soils
      cellar_work
      productivity
      philosophy
      vat
      fiscal
      country
      city
      province
      lat_lng {
        lat
        lng
        address
      }
      location
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      certifications {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyCertificationsId
        }
        nextToken
      }
      products {
        items {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        nextToken
      }
      categories {
        items {
          id
          name
          products {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      device_groups {
        items {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatch {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      team
      company_photo {
        key
        company
      }
      createdAt
      updatedAt
      companyFarmId
      companyUserId
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      company_name
      year_of_foundation
      history_of_company
      website
      production_per_year
      production_area
      soils
      cellar_work
      productivity
      philosophy
      vat
      fiscal
      country
      city
      province
      lat_lng {
        lat
        lng
        address
      }
      location
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      certifications {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyCertificationsId
        }
        nextToken
      }
      products {
        items {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        nextToken
      }
      categories {
        items {
          id
          name
          products {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      device_groups {
        items {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatch {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      team
      company_photo {
        key
        company
      }
      createdAt
      updatedAt
      companyFarmId
      companyUserId
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      company_name
      year_of_foundation
      history_of_company
      website
      production_per_year
      production_area
      soils
      cellar_work
      productivity
      philosophy
      vat
      fiscal
      country
      city
      province
      lat_lng {
        lat
        lng
        address
      }
      location
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      certifications {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyCertificationsId
        }
        nextToken
      }
      products {
        items {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        nextToken
      }
      categories {
        items {
          id
          name
          products {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      device_groups {
        items {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dispatch {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      team
      company_photo {
        key
        company
      }
      createdAt
      updatedAt
      companyFarmId
      companyUserId
    }
  }
`;
export const onCreateMedia = /* GraphQL */ `
  subscription OnCreateMedia {
    onCreateMedia {
      id
      key
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyMediaId
      productMediaId
    }
  }
`;
export const onUpdateMedia = /* GraphQL */ `
  subscription OnUpdateMedia {
    onUpdateMedia {
      id
      key
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyMediaId
      productMediaId
    }
  }
`;
export const onDeleteMedia = /* GraphQL */ `
  subscription OnDeleteMedia {
    onDeleteMedia {
      id
      key
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyMediaId
      productMediaId
    }
  }
`;
export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument {
    onCreateDocument {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyDocumentationId
      productDocumentationId
    }
  }
`;
export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument {
    onUpdateDocument {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyDocumentationId
      productDocumentationId
    }
  }
`;
export const onDeleteDocument = /* GraphQL */ `
  subscription OnDeleteDocument {
    onDeleteDocument {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyDocumentationId
      productDocumentationId
    }
  }
`;
export const onCreateCertificate = /* GraphQL */ `
  subscription OnCreateCertificate {
    onCreateCertificate {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyCertificationsId
    }
  }
`;
export const onUpdateCertificate = /* GraphQL */ `
  subscription OnUpdateCertificate {
    onUpdateCertificate {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyCertificationsId
    }
  }
`;
export const onDeleteCertificate = /* GraphQL */ `
  subscription OnDeleteCertificate {
    onDeleteCertificate {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
      companyCertificationsId
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct {
    onCreateProduct {
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      id
      title
      status
      category {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      quantity
      units
      description
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      serial_number
      manufacturing_date
      expiry_date
      invoice_number
      purchase_order
      rfid_number
      batch_id
      location {
        lat
        lng
        address
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      parent_products {
        id
        type
      }
      farmID
      paddocks
      qr_settings {
        location
        history_of_company
        media
        documentation
        year_of_foundation
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        certifications
        philosophy
        devices {
          device_type
          config
        }
        datetime_creation
        rules_business_conditions
        datetime_dispatch
        datetime_receive
        datetime_return
        transaction_history
        device_rule_log
        basic_info
        parent_products
        list_of_dispatches
        linked_IOT_devices
        smart_conditions
        actions_info
      }
      dispatches
      receiveData {
        dispatchID
        companyID
        receiveDate
      }
      createdAt
      updatedAt
      companyProductsId
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct {
    onUpdateProduct {
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      id
      title
      status
      category {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      quantity
      units
      description
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      serial_number
      manufacturing_date
      expiry_date
      invoice_number
      purchase_order
      rfid_number
      batch_id
      location {
        lat
        lng
        address
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      parent_products {
        id
        type
      }
      farmID
      paddocks
      qr_settings {
        location
        history_of_company
        media
        documentation
        year_of_foundation
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        certifications
        philosophy
        devices {
          device_type
          config
        }
        datetime_creation
        rules_business_conditions
        datetime_dispatch
        datetime_receive
        datetime_return
        transaction_history
        device_rule_log
        basic_info
        parent_products
        list_of_dispatches
        linked_IOT_devices
        smart_conditions
        actions_info
      }
      dispatches
      receiveData {
        dispatchID
        companyID
        receiveDate
      }
      createdAt
      updatedAt
      companyProductsId
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct {
    onDeleteProduct {
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      id
      title
      status
      category {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      quantity
      units
      description
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
        }
        nextToken
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
        }
        nextToken
      }
      serial_number
      manufacturing_date
      expiry_date
      invoice_number
      purchase_order
      rfid_number
      batch_id
      location {
        lat
        lng
        address
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      parent_products {
        id
        type
      }
      farmID
      paddocks
      qr_settings {
        location
        history_of_company
        media
        documentation
        year_of_foundation
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        certifications
        philosophy
        devices {
          device_type
          config
        }
        datetime_creation
        rules_business_conditions
        datetime_dispatch
        datetime_receive
        datetime_return
        transaction_history
        device_rule_log
        basic_info
        parent_products
        list_of_dispatches
        linked_IOT_devices
        smart_conditions
        actions_info
      }
      dispatches
      receiveData {
        dispatchID
        companyID
        receiveDate
      }
      createdAt
      updatedAt
      companyProductsId
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory {
    onCreateCategory {
      id
      name
      products {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory {
    onUpdateCategory {
      id
      name
      products {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory {
    onDeleteCategory {
      id
      name
      products {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice {
    onCreateDevice {
      id
      name
      category
      description
      date_of_manufacture
      date_of_purchase
      location {
        lat
        lng
        address
      }
      device_info
      status
      iot_dashboard_active
      device_type
      config {
        device_id
        getaway_id
        band
      }
      alerts {
        items {
          id
          name
          datetime
          description
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          ruleId
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      productID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      categoryID
      device_category {
        id
        slug
        name
        color
        icon
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      device_groupID
      device_group {
        id
        name
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      paddockID
      device_paddock {
        id
        type
        basic_info {
          name
          total_area
          land_use
          colour_on_map
          description
          type
          breed
          date_of_birth
          withholding_time
          dosage
          dosage_rate
          date_of_the_treatment
          container_unit
          container_size
          container_prize
          expiry_date
          size
          data
          withholding
          weight
          cost
          batch_number
          plots_config {
            crop
            cropOther
            variety
            crop_year
          }
          status
          sex
          tag_number
          birth_weight
          raised_or_purchased
          disposal
          disposal_date
          position
          brand
          model
          working_width
          power
          production_year
          plate_number
          frame_number
          date_of_purchase
          revision_date
          date_of_technical_check
          registration_document
          insurance_document
          user_manual
          photo
          custom_type
        }
        map_info {
          center_lat
          center_lng
          type
          points {
            lat
            lng
          }
          radius
        }
        farmID
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        child_paddock
        soil {
          soil_map
          granulometry
          sand
          slit
          clay
          ph
          carbonates
          organic_substance
          organic_carbon
          mineral_nitrogen
        }
        land_register
        createdAt
        updatedAt
      }
      device_iot_data_id
      device_from_iot_data
      custom_category
      shedule
      sheduleRepeat
      periodOfActivation
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice {
    onUpdateDevice {
      id
      name
      category
      description
      date_of_manufacture
      date_of_purchase
      location {
        lat
        lng
        address
      }
      device_info
      status
      iot_dashboard_active
      device_type
      config {
        device_id
        getaway_id
        band
      }
      alerts {
        items {
          id
          name
          datetime
          description
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          ruleId
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      productID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      categoryID
      device_category {
        id
        slug
        name
        color
        icon
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      device_groupID
      device_group {
        id
        name
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      paddockID
      device_paddock {
        id
        type
        basic_info {
          name
          total_area
          land_use
          colour_on_map
          description
          type
          breed
          date_of_birth
          withholding_time
          dosage
          dosage_rate
          date_of_the_treatment
          container_unit
          container_size
          container_prize
          expiry_date
          size
          data
          withholding
          weight
          cost
          batch_number
          plots_config {
            crop
            cropOther
            variety
            crop_year
          }
          status
          sex
          tag_number
          birth_weight
          raised_or_purchased
          disposal
          disposal_date
          position
          brand
          model
          working_width
          power
          production_year
          plate_number
          frame_number
          date_of_purchase
          revision_date
          date_of_technical_check
          registration_document
          insurance_document
          user_manual
          photo
          custom_type
        }
        map_info {
          center_lat
          center_lng
          type
          points {
            lat
            lng
          }
          radius
        }
        farmID
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        child_paddock
        soil {
          soil_map
          granulometry
          sand
          slit
          clay
          ph
          carbonates
          organic_substance
          organic_carbon
          mineral_nitrogen
        }
        land_register
        createdAt
        updatedAt
      }
      device_iot_data_id
      device_from_iot_data
      custom_category
      shedule
      sheduleRepeat
      periodOfActivation
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice {
    onDeleteDevice {
      id
      name
      category
      description
      date_of_manufacture
      date_of_purchase
      location {
        lat
        lng
        address
      }
      device_info
      status
      iot_dashboard_active
      device_type
      config {
        device_id
        getaway_id
        band
      }
      alerts {
        items {
          id
          name
          datetime
          description
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          ruleId
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      productID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      categoryID
      device_category {
        id
        slug
        name
        color
        icon
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      device_groupID
      device_group {
        id
        name
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      paddockID
      device_paddock {
        id
        type
        basic_info {
          name
          total_area
          land_use
          colour_on_map
          description
          type
          breed
          date_of_birth
          withholding_time
          dosage
          dosage_rate
          date_of_the_treatment
          container_unit
          container_size
          container_prize
          expiry_date
          size
          data
          withholding
          weight
          cost
          batch_number
          plots_config {
            crop
            cropOther
            variety
            crop_year
          }
          status
          sex
          tag_number
          birth_weight
          raised_or_purchased
          disposal
          disposal_date
          position
          brand
          model
          working_width
          power
          production_year
          plate_number
          frame_number
          date_of_purchase
          revision_date
          date_of_technical_check
          registration_document
          insurance_document
          user_manual
          photo
          custom_type
        }
        map_info {
          center_lat
          center_lng
          type
          points {
            lat
            lng
          }
          radius
        }
        farmID
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        child_paddock
        soil {
          soil_map
          granulometry
          sand
          slit
          clay
          ph
          carbonates
          organic_substance
          organic_carbon
          mineral_nitrogen
        }
        land_register
        createdAt
        updatedAt
      }
      device_iot_data_id
      device_from_iot_data
      custom_category
      shedule
      sheduleRepeat
      periodOfActivation
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDeviceCategory = /* GraphQL */ `
  subscription OnCreateDeviceCategory {
    onCreateDeviceCategory {
      id
      slug
      name
      color
      icon
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDeviceCategory = /* GraphQL */ `
  subscription OnUpdateDeviceCategory {
    onUpdateDeviceCategory {
      id
      slug
      name
      color
      icon
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDeviceCategory = /* GraphQL */ `
  subscription OnDeleteDeviceCategory {
    onDeleteDeviceCategory {
      id
      slug
      name
      color
      icon
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDeviceGroup = /* GraphQL */ `
  subscription OnCreateDeviceGroup {
    onCreateDeviceGroup {
      id
      name
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDeviceGroup = /* GraphQL */ `
  subscription OnUpdateDeviceGroup {
    onUpdateDeviceGroup {
      id
      name
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDeviceGroup = /* GraphQL */ `
  subscription OnDeleteDeviceGroup {
    onDeleteDeviceGroup {
      id
      name
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRules = /* GraphQL */ `
  subscription OnCreateRules {
    onCreateRules {
      id
      name
      status
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      behavior {
        observation_period
        parameter
        behavior
        day_of_the_month
        day_of_the_week
        time_of_observation_from
        time_of_observation_to
        operator
        value
        temporal_settings
        telemetry
        aggregation
      }
      alert_actions {
        sms_email
        dashboard
        m2m
        webhook
        display_name
        note
        mode
        email
        tel
        deviceID
        device_action
        callback_url
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRules = /* GraphQL */ `
  subscription OnUpdateRules {
    onUpdateRules {
      id
      name
      status
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      behavior {
        observation_period
        parameter
        behavior
        day_of_the_month
        day_of_the_week
        time_of_observation_from
        time_of_observation_to
        operator
        value
        temporal_settings
        telemetry
        aggregation
      }
      alert_actions {
        sms_email
        dashboard
        m2m
        webhook
        display_name
        note
        mode
        email
        tel
        deviceID
        device_action
        callback_url
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRules = /* GraphQL */ `
  subscription OnDeleteRules {
    onDeleteRules {
      id
      name
      status
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      behavior {
        observation_period
        parameter
        behavior
        day_of_the_month
        day_of_the_week
        time_of_observation_from
        time_of_observation_to
        operator
        value
        temporal_settings
        telemetry
        aggregation
      }
      alert_actions {
        sms_email
        dashboard
        m2m
        webhook
        display_name
        note
        mode
        email
        tel
        deviceID
        device_action
        callback_url
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlert = /* GraphQL */ `
  subscription OnCreateAlert {
    onCreateAlert {
      id
      name
      datetime
      description
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      ruleId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAlert = /* GraphQL */ `
  subscription OnUpdateAlert {
    onUpdateAlert {
      id
      name
      datetime
      description
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      ruleId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAlert = /* GraphQL */ `
  subscription OnDeleteAlert {
    onDeleteAlert {
      id
      name
      datetime
      description
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        location {
          lat
          lng
          address
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          id
          title
          status
          category {
            nextToken
          }
          quantity
          units
          description
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
          }
          devices {
            nextToken
          }
          parent_products {
            id
            type
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
          }
          createdAt
          updatedAt
          companyProductsId
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
          }
          createdAt
          updatedAt
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
      }
      ruleId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDispatch = /* GraphQL */ `
  subscription OnCreateDispatch {
    onCreateDispatch {
      id
      dispatchID
      status
      ownerID
      owner {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companies {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingCompanies
      companiesStatus {
        id
        type
        status
        dispatchModifiedID
        pending_date
        approved_date
        delivery_date
        incoming_date
        reject_date
        return_date
        cancel_date
        delivered_date
        receive_date
      }
      documents {
        key
        title
      }
      batches {
        id
        name
        products
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
      }
      contract_conditions {
        condition_type
        select_batch_id
        select_device
        parameter_obeserved
        operator
        value
        type
        event_to_be_monitored
        custom_event
        from_date
        from_time
        to_date
        to_time
        date
        time
        description_of_certifications_required
        description_of_the_business_rule
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDispatch = /* GraphQL */ `
  subscription OnUpdateDispatch {
    onUpdateDispatch {
      id
      dispatchID
      status
      ownerID
      owner {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companies {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingCompanies
      companiesStatus {
        id
        type
        status
        dispatchModifiedID
        pending_date
        approved_date
        delivery_date
        incoming_date
        reject_date
        return_date
        cancel_date
        delivered_date
        receive_date
      }
      documents {
        key
        title
      }
      batches {
        id
        name
        products
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
      }
      contract_conditions {
        condition_type
        select_batch_id
        select_device
        parameter_obeserved
        operator
        value
        type
        event_to_be_monitored
        custom_event
        from_date
        from_time
        to_date
        to_time
        date
        time
        description_of_certifications_required
        description_of_the_business_rule
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDispatch = /* GraphQL */ `
  subscription OnDeleteDispatch {
    onDeleteDispatch {
      id
      dispatchID
      status
      ownerID
      owner {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
          }
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
      }
      companies {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingCompanies
      companiesStatus {
        id
        type
        status
        dispatchModifiedID
        pending_date
        approved_date
        delivery_date
        incoming_date
        reject_date
        return_date
        cancel_date
        delivered_date
        receive_date
      }
      documents {
        key
        title
      }
      batches {
        id
        name
        products
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
      }
      contract_conditions {
        condition_type
        select_batch_id
        select_device
        parameter_obeserved
        operator
        value
        type
        event_to_be_monitored
        custom_event
        from_date
        from_time
        to_date
        to_time
        date
        time
        description_of_certifications_required
        description_of_the_business_rule
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFarm = /* GraphQL */ `
  subscription OnCreateFarm {
    onCreateFarm {
      id
      paddocks {
        items {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFarm = /* GraphQL */ `
  subscription OnUpdateFarm {
    onUpdateFarm {
      id
      paddocks {
        items {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFarm = /* GraphQL */ `
  subscription OnDeleteFarm {
    onDeleteFarm {
      id
      paddocks {
        items {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
          }
          map_info {
            center_lat
            center_lng
            type
            radius
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          devices {
            nextToken
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
          }
          land_register
          createdAt
          updatedAt
        }
        nextToken
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePaddock = /* GraphQL */ `
  subscription OnCreatePaddock {
    onCreatePaddock {
      id
      type
      basic_info {
        name
        total_area
        land_use
        colour_on_map
        description
        type
        breed
        date_of_birth
        withholding_time
        dosage
        dosage_rate
        date_of_the_treatment
        container_unit
        container_size
        container_prize
        expiry_date
        size
        data
        withholding
        weight
        cost
        batch_number
        plots_config {
          crop
          cropOther
          variety
          crop_year
        }
        status
        sex
        tag_number
        birth_weight
        raised_or_purchased
        disposal
        disposal_date
        position
        brand
        model
        working_width
        power
        production_year
        plate_number
        frame_number
        date_of_purchase
        revision_date
        date_of_technical_check
        registration_document
        insurance_document
        user_manual
        photo
        custom_type
      }
      map_info {
        center_lat
        center_lng
        type
        points {
          lat
          lng
        }
        radius
      }
      farmID
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      child_paddock
      soil {
        soil_map
        granulometry
        sand
        slit
        clay
        ph
        carbonates
        organic_substance
        organic_carbon
        mineral_nitrogen
      }
      land_register
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePaddock = /* GraphQL */ `
  subscription OnUpdatePaddock {
    onUpdatePaddock {
      id
      type
      basic_info {
        name
        total_area
        land_use
        colour_on_map
        description
        type
        breed
        date_of_birth
        withholding_time
        dosage
        dosage_rate
        date_of_the_treatment
        container_unit
        container_size
        container_prize
        expiry_date
        size
        data
        withholding
        weight
        cost
        batch_number
        plots_config {
          crop
          cropOther
          variety
          crop_year
        }
        status
        sex
        tag_number
        birth_weight
        raised_or_purchased
        disposal
        disposal_date
        position
        brand
        model
        working_width
        power
        production_year
        plate_number
        frame_number
        date_of_purchase
        revision_date
        date_of_technical_check
        registration_document
        insurance_document
        user_manual
        photo
        custom_type
      }
      map_info {
        center_lat
        center_lng
        type
        points {
          lat
          lng
        }
        radius
      }
      farmID
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      child_paddock
      soil {
        soil_map
        granulometry
        sand
        slit
        clay
        ph
        carbonates
        organic_substance
        organic_carbon
        mineral_nitrogen
      }
      land_register
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePaddock = /* GraphQL */ `
  subscription OnDeletePaddock {
    onDeletePaddock {
      id
      type
      basic_info {
        name
        total_area
        land_use
        colour_on_map
        description
        type
        breed
        date_of_birth
        withholding_time
        dosage
        dosage_rate
        date_of_the_treatment
        container_unit
        container_size
        container_prize
        expiry_date
        size
        data
        withholding
        weight
        cost
        batch_number
        plots_config {
          crop
          cropOther
          variety
          crop_year
        }
        status
        sex
        tag_number
        birth_weight
        raised_or_purchased
        disposal
        disposal_date
        position
        brand
        model
        working_width
        power
        production_year
        plate_number
        frame_number
        date_of_purchase
        revision_date
        date_of_technical_check
        registration_document
        insurance_document
        user_manual
        photo
        custom_type
      }
      map_info {
        center_lat
        center_lng
        type
        points {
          lat
          lng
        }
        radius
      }
      farmID
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          location {
            lat
            lng
            address
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
          }
          alerts {
            nextToken
          }
          rules {
            nextToken
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
        }
        nextToken
      }
      child_paddock
      soil {
        soil_map
        granulometry
        sand
        slit
        clay
        ph
        carbonates
        organic_substance
        organic_carbon
        mineral_nitrogen
      }
      land_register
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFarmAction = /* GraphQL */ `
  subscription OnCreateFarmAction {
    onCreateFarmAction {
      id
      category
      content_for_100g
      type_of_acivity
      phenological_stadium
      tool_equipment
      name_of_the_operation
      date_of_the_operation
      name_of_the_product
      active_substance
      dose_hectar
      total_quantity
      total_water_distributed
      adversity
      type_of_adversity
      reason_for_the_treatment
      name_of_the_person
      date_of_start_of_the_operation
      date_of_end_of_the_operation
      time_of_start_of_the_operation
      time_of_end_of_the_operation
      hours_worked
      wind_indensity
      weatcher_conditions
      notes_on_animals_and_plants
      notes_on_furrows
      pest_noticed
      irrigation_system_conditions
      irrigation_system_notes
      other_notes
      related
      company_id
      related_persons
      type_of_vaccine_treatment
      withholding_time
      dosage
      dosage_rate
      date_of_the_treatment
      container_unit
      container_size
      container_prize
      expiry_date
      description
      type_of_product
      product_category
      intervention_reason
      visible_on_qrcode
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFarmAction = /* GraphQL */ `
  subscription OnUpdateFarmAction {
    onUpdateFarmAction {
      id
      category
      content_for_100g
      type_of_acivity
      phenological_stadium
      tool_equipment
      name_of_the_operation
      date_of_the_operation
      name_of_the_product
      active_substance
      dose_hectar
      total_quantity
      total_water_distributed
      adversity
      type_of_adversity
      reason_for_the_treatment
      name_of_the_person
      date_of_start_of_the_operation
      date_of_end_of_the_operation
      time_of_start_of_the_operation
      time_of_end_of_the_operation
      hours_worked
      wind_indensity
      weatcher_conditions
      notes_on_animals_and_plants
      notes_on_furrows
      pest_noticed
      irrigation_system_conditions
      irrigation_system_notes
      other_notes
      related
      company_id
      related_persons
      type_of_vaccine_treatment
      withholding_time
      dosage
      dosage_rate
      date_of_the_treatment
      container_unit
      container_size
      container_prize
      expiry_date
      description
      type_of_product
      product_category
      intervention_reason
      visible_on_qrcode
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFarmAction = /* GraphQL */ `
  subscription OnDeleteFarmAction {
    onDeleteFarmAction {
      id
      category
      content_for_100g
      type_of_acivity
      phenological_stadium
      tool_equipment
      name_of_the_operation
      date_of_the_operation
      name_of_the_product
      active_substance
      dose_hectar
      total_quantity
      total_water_distributed
      adversity
      type_of_adversity
      reason_for_the_treatment
      name_of_the_person
      date_of_start_of_the_operation
      date_of_end_of_the_operation
      time_of_start_of_the_operation
      time_of_end_of_the_operation
      hours_worked
      wind_indensity
      weatcher_conditions
      notes_on_animals_and_plants
      notes_on_furrows
      pest_noticed
      irrigation_system_conditions
      irrigation_system_notes
      other_notes
      related
      company_id
      related_persons
      type_of_vaccine_treatment
      withholding_time
      dosage
      dosage_rate
      date_of_the_treatment
      container_unit
      container_size
      container_prize
      expiry_date
      description
      type_of_product
      product_category
      intervention_reason
      visible_on_qrcode
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFarmReports = /* GraphQL */ `
  subscription OnCreateFarmReports {
    onCreateFarmReports {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFarmReports = /* GraphQL */ `
  subscription OnUpdateFarmReports {
    onUpdateFarmReports {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFarmReports = /* GraphQL */ `
  subscription OnDeleteFarmReports {
    onDeleteFarmReports {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReport = /* GraphQL */ `
  subscription OnCreateReport {
    onCreateReport {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReport = /* GraphQL */ `
  subscription OnUpdateReport {
    onUpdateReport {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReport = /* GraphQL */ `
  subscription OnDeleteReport {
    onDeleteReport {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomReport = /* GraphQL */ `
  subscription OnCreateCustomReport {
    onCreateCustomReport {
      id
      name
      type
      number
      product
      images
      comments
      tableColumns
      tableData
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomReport = /* GraphQL */ `
  subscription OnUpdateCustomReport {
    onUpdateCustomReport {
      id
      name
      type
      number
      product
      images
      comments
      tableColumns
      tableData
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomReport = /* GraphQL */ `
  subscription OnDeleteCustomReport {
    onDeleteCustomReport {
      id
      name
      type
      number
      product
      images
      comments
      tableColumns
      tableData
      related_persons
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSupportTickets = /* GraphQL */ `
  subscription OnCreateSupportTickets {
    onCreateSupportTickets {
      id
      date
      status
      category
      subject
      description
      user
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSupportTickets = /* GraphQL */ `
  subscription OnUpdateSupportTickets {
    onUpdateSupportTickets {
      id
      date
      status
      category
      subject
      description
      user
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSupportTickets = /* GraphQL */ `
  subscription OnDeleteSupportTickets {
    onDeleteSupportTickets {
      id
      date
      status
      category
      subject
      description
      user
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUpcomingCalls = /* GraphQL */ `
  subscription OnCreateUpcomingCalls {
    onCreateUpcomingCalls {
      id
      date
      time
      user
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUpcomingCalls = /* GraphQL */ `
  subscription OnUpdateUpcomingCalls {
    onUpdateUpcomingCalls {
      id
      date
      time
      user
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUpcomingCalls = /* GraphQL */ `
  subscription OnDeleteUpcomingCalls {
    onDeleteUpcomingCalls {
      id
      date
      time
      user
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFaq = /* GraphQL */ `
  subscription OnCreateFaq {
    onCreateFaq {
      id
      question
      answer
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFaq = /* GraphQL */ `
  subscription OnUpdateFaq {
    onUpdateFaq {
      id
      question
      answer
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFaq = /* GraphQL */ `
  subscription OnDeleteFaq {
    onDeleteFaq {
      id
      question
      answer
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompanyDispatch = /* GraphQL */ `
  subscription OnCreateCompanyDispatch {
    onCreateCompanyDispatch {
      id
      companyID
      dispatchID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      dispatch {
        id
        dispatchID
        status
        ownerID
        owner {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companies {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        incomingCompanies
        companiesStatus {
          id
          type
          status
          dispatchModifiedID
          pending_date
          approved_date
          delivery_date
          incoming_date
          reject_date
          return_date
          cancel_date
          delivered_date
          receive_date
        }
        documents {
          key
          title
        }
        batches {
          id
          name
          products
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
        }
        contract_conditions {
          condition_type
          select_batch_id
          select_device
          parameter_obeserved
          operator
          value
          type
          event_to_be_monitored
          custom_event
          from_date
          from_time
          to_date
          to_time
          date
          time
          description_of_certifications_required
          description_of_the_business_rule
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompanyDispatch = /* GraphQL */ `
  subscription OnUpdateCompanyDispatch {
    onUpdateCompanyDispatch {
      id
      companyID
      dispatchID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      dispatch {
        id
        dispatchID
        status
        ownerID
        owner {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companies {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        incomingCompanies
        companiesStatus {
          id
          type
          status
          dispatchModifiedID
          pending_date
          approved_date
          delivery_date
          incoming_date
          reject_date
          return_date
          cancel_date
          delivered_date
          receive_date
        }
        documents {
          key
          title
        }
        batches {
          id
          name
          products
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
        }
        contract_conditions {
          condition_type
          select_batch_id
          select_device
          parameter_obeserved
          operator
          value
          type
          event_to_be_monitored
          custom_event
          from_date
          from_time
          to_date
          to_time
          date
          time
          description_of_certifications_required
          description_of_the_business_rule
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompanyDispatch = /* GraphQL */ `
  subscription OnDeleteCompanyDispatch {
    onDeleteCompanyDispatch {
      id
      companyID
      dispatchID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
          }
          nextToken
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
          }
          nextToken
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        farm {
          id
          paddocks {
            nextToken
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          createdAt
          updatedAt
        }
        team
        company_photo {
          key
          company
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
      }
      dispatch {
        id
        dispatchID
        status
        ownerID
        owner {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
          }
          rules {
            nextToken
          }
          dispatches {
            nextToken
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
          }
          teamID
          api_pofiles {
            name
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
        }
        companies {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
          }
          nextToken
        }
        incomingCompanies
        companiesStatus {
          id
          type
          status
          dispatchModifiedID
          pending_date
          approved_date
          delivery_date
          incoming_date
          reject_date
          return_date
          cancel_date
          delivered_date
          receive_date
        }
        documents {
          key
          title
        }
        batches {
          id
          name
          products
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
          }
        }
        contract_conditions {
          condition_type
          select_batch_id
          select_device
          parameter_obeserved
          operator
          value
          type
          event_to_be_monitored
          custom_event
          from_date
          from_time
          to_date
          to_time
          date
          time
          description_of_certifications_required
          description_of_the_business_rule
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProductsCategory = /* GraphQL */ `
  subscription OnCreateProductsCategory {
    onCreateProductsCategory {
      id
      productID
      categoryID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      category {
        id
        name
        products {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProductsCategory = /* GraphQL */ `
  subscription OnUpdateProductsCategory {
    onUpdateProductsCategory {
      id
      productID
      categoryID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      category {
        id
        name
        products {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProductsCategory = /* GraphQL */ `
  subscription OnDeleteProductsCategory {
    onDeleteProductsCategory {
      id
      productID
      categoryID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
          }
          nextToken
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
          }
          nextToken
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
          }
          nextToken
        }
        parent_products {
          id
          type
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
        }
        createdAt
        updatedAt
        companyProductsId
      }
      category {
        id
        name
        products {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
          }
          media {
            nextToken
          }
          documentation {
            nextToken
          }
          certifications {
            nextToken
          }
          products {
            nextToken
          }
          categories {
            nextToken
          }
          devices {
            nextToken
          }
          device_groups {
            nextToken
          }
          rules {
            nextToken
          }
          dispatch {
            nextToken
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
          }
          team
          company_photo {
            key
            company
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
