<template>
    <div class="categories dropdown-wrapper flex-auto bg-left search-icon" v-click-outside="closeMe"> 
        <button 
            type="button" 
            class="btn btn-standart dropdown-btn" 
            :class="[{ active: open }, btnClass, {disabled:disabled}]" 
            @click="toggleMe" 
        >
            <span class="active-categories">{{ getCurrent.name ? getCurrent.name : placeholder ? placeholder : 'Select Option' }}</span>
            <span><img src="@/assets/img/Arrow-down.svg"></span>
        </button>
        <div v-if="open"  ref="content" class="categories__dropdown dropdown-content active flex-col" style="overflow:initial;max-height:400px;">
            
            <input v-if="search" type="text" class="input search" v-model="searchString" placeholder="Search">
            <!-- <DynamicScroller v-if="catigories" :items="filteredOptions" :min-item-size="10" :prerender="14" class="dropdown">
                
                <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem  :item="item" :active="active" :size-dependencies="[item.message]" :data-index="index" >
                        
                        <div class="dropdown_item " @click="change(item)" >{{ item.name }}</div>
                        <div class="dropdown_item" @click="change(item)" >{{ item.name }}</div>
                    </DynamicScrollerItem>
                </template>
            </DynamicScroller> -->
            <DynamicScroller :items="filteredOptions" :min-item-size="10" :prerender="14" class="dropdown" >
                
                <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem  :item="item" :active="active" :size-dependencies="[item.message]" :data-index="index">
                        
                        <div v-if="item.isCategory" class="dropdown_item dropdown_item-category" :class="{ 'active':item.id === getCurrent.id }">{{ item.name }}</div>
                        <div v-else class="dropdown_item" @click="change(item)" :class="{ 'active':item.id === getCurrent.id }">{{ item.name }}</div>
                    </DynamicScrollerItem>
                </template>
            </DynamicScroller>
        </div>
        <div v-if="open" ref="bottom" class="dropdown__bottom-block" ></div>

    </div>
</template>

<script>
import { DynamicScroller,DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
export default {
    name: 'DropdownDynamic',
    props: ['options', 'current', 'name', 'shadow',"disabled",'placeholder','search','categories','useID'],
    components:{
        DynamicScroller,
        DynamicScrollerItem
    },
	model: {
      	prop: 'current',
    
  	},
    watch:{
        open(){
            if(this.open){
                this.$nextTick(()=>{
                    this.$refs.bottom.style.top=this.$refs.content.clientHeight+30+'px'
                })
            }
        }
    },
    data() {
        return {
            open: false,
            searchString:null,
        }
    },
    computed: {
        btnClass() {
            return this.shadow ? 'btn-w-shadow' : 'none-shadow'
        },
		getCurrent() {
			return this.optionsTransformed.find(o => o.id === this.current) || {}
		},
        filteredOptions(){

            if(this.searchString){
                let filtered= this.optionsTransformed.filter(option=>option.name.toLowerCase().includes(this.searchString.toLowerCase())&&!option.isCategory)
                return filtered
            }else{
                return this.optionsTransformed
            }
        },
        optionsTransformed(){
            if(this.categories){
                let options=[]
                Object.entries(this.options).forEach(([key,value])=>{
                    options.push({id:key+"_category",name:key,isCategory:true})
                    value.forEach(o=>{
                        if(this.useID){
                            options.push({id:o.id,name:o.name})
                        }
                        else{
                            options.push({id:key+"_"+o,name:o})
                        }
                    })
                })
                return options
            }else{
                return this.options
            }
        }
    },
    methods: {
        change(option) {
            const event = {
                target: {
                    value: option,
                    name: this.name
                }
            }
            this.$emit('change', event);
			this.$emit('input', option.id);
            this.closeMe();
        },
        closeMe() {
            this.open = false
        },
        toggleMe() {
            this.open = !this.open
        }
    }
}
</script>

<style scoped>
    .search{
        margin-bottom: 10px;
    }
    .dropdown_item{
        display: block;
        padding: 8px 14px;
        cursor: pointer;
        border-radius: 7px;
        text-transform: capitalize;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        text-align: start;
    }
    .dropdown_item:hover{
        background: var(--table-row-hover);
    }
    .dropdown{
        width:100%;
    }
    .disabled{
        pointer-events: none;
        background: #F6F8F9;
    }
    .dropdown_item-category{
        font-weight: 700;
    }
     .dropdown_item-category:hover{
        background: none;
        cursor:default;
    }
    .dropdown_item.active {
        background: #F6F8F9;
    }
</style>
<style>
    .vue-recycle-scroller__item-view span{

    }
</style>






