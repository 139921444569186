<template>
	<div class="popup-iot-dashboard" style="width: 100%;z-index: 99999999;" v-click-outside="endTutor">
		<div class="popup-iot-dashboard-content">
			<!-- <h3 class="heading-page-h1">
				{{ currentTutorial.title }}
			</h3> -->
			<!-- <p>
				{{ currentTutorial.text }}
			</p> -->
			<div class="actions flex" style="margin: 0;">
				<div class="actions-item">
					<button
						@click="endTutor" 
						class="btn btn-action btn-sm btn-center close-popup"
					>
						{{ $translate('end-tutorial') }}
					</button>
				</div>
				<div class="actions-item">
					<button
						:class="{ disabled: !canPrev }" 
						class="btn btn-action btn-sm btn-center prev-btn" 
						@click="prev"
					>
						<inline-svg :src="require('@/assets/img/arrow-icon.svg')" />
						<span>{{ $translate('previous') }}</span>
					</button>
					<button 
						:class="{ disabled: !canNext }"
						class="btn btn-primary btn-sm next-btn" 
						@click="next"
					>
						<span>{{ $translate('next') }}</span>
						<inline-svg :src="require('@/assets/img/arrow-icon.svg')" />
					</button>
				</div>
			</div>
			<div class="progress-bar">
				<div class="linear-progress">
					<span class="progress" :style="{ width: progress + '%' }"></span>
				</div>
				<span class="number-progress">
					{{ current + 1 }} / {{ tutorials.length }}
				</span>
			</div>
		</div>
	</div>	
</template>

<script>
import { tutorialConfig } from '@/config/tutorial'
export default {
	name: 'Tutorial',
	data() {
		return {
			current: 0,
			fromOutside: false,
			tutorRendered: false,
		}
	},
	watch: {
		current(value){
			setTimeout(() => {
				this.tooltipsRender();
			}, 600);
		}
	},
	computed: {
		tutorials() {
			return tutorialConfig
		},
		currentTutorial() {
			return this.tutorials[this.current]
		},
		canNext() {
			return this.current < this.tutorials.length - 1
		},
		canPrev() {
			return this.current > 0
		},
		progress() {
			return Math.ceil(100 / this.tutorials.length * (this.current + 1))
		}
	},
	methods: {
		next() {
			if(!this.canNext) return
			this.current++
			this.switchRoute()
		},
		prev() {
			if(!this.canPrev) return
			this.current--
			this.switchRoute()
		},
		switchRoute() {
			this.$router.push(this.currentTutorial.path)
		},
		tooltipsRender(){
			if(document.querySelectorAll('.tutorial_tooltip')){
				document.querySelectorAll('.tutorial_tooltip').forEach(element => {
					element.remove();
				});
			}

			setTimeout(() => {
				this.currentTutorial.text.forEach(textElement => {
					var newTooltip = document.createElement('div');
					newTooltip.className = textElement.class ? `tutorial_tooltip ${textElement.class}` : 'tutorial_tooltip';
					newTooltip.innerHTML = `
						<span class="tooltip_hidden_symbol">?</span>
						<span class="tooltip_main_text">${textElement.text}</span>
					`;

					if(document.querySelector(textElement.element)) {
						document.querySelector(textElement.element).appendChild(newTooltip);
					}
				})
			}, 50);
		},
		endTutor(){
			if(this.tutorRendered){
				if(!this.fromOutside) {
					let self = this;
					const options = { title: "Close tutorial?", size: "sm", okLabel: "Yes", cancelLabel: "No" };
					this.$dialogs.confirm("", options).then((res) => {
						if (res.ok) {
							if(document.querySelectorAll('.tutorial_tooltip')){
								document.querySelectorAll('.tutorial_tooltip').forEach(element => {
									element.remove();
								});
							}

							self.$emit('toggle');
							self.$router.push('/');

							setTimeout(() => {
								window.location.reload();
							}, 200);
						} else {
							this.fromOutside = false;
						}
					});
				}
				this.fromOutside = true;
			}
		},
	}, 
	created(){
		this.switchRoute();

		setTimeout(() => {
			this.tooltipsRender();
		}, 600);
	},
	mounted(){
		setTimeout(() => {
			this.tutorRendered = true;
		}, 1000);
	}
}
</script>

<style scoped>
.btn {
	user-select: none;
}
.btn.disabled {
	pointer-events: none;
	background-color: #e8f1f4;
	color: #acbcc2;
}
.progress {
	transition: width 0.3s ease-in-out;
}
</style>