<template>
    <div v-html="icon ? icons[icon] : ''" class="svg_icons_wrpr"></div>
</template>

<script>
export default {
    props: ['icon', 'styles'],
    data(){
        return {
            icons: {
                'filter-icon.svg': `<svg fill="none" viewBox="0 0 18 19" height="19" width="18" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <path xmlns="http://www.w3.org/2000/svg" d="M11.5 10.5L16.707 5.293C16.895 5.105 17 4.851 17 4.586V2C17 1.448 16.552 1 16 1H2C1.448 1 1 1.448 1 2V4.586C1 4.851 1.105 5.106 1.293 5.293L6.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M6.5 10.5V16.749C6.5 17.562 7.264 18.159 8.053 17.962L10.553 17.337C11.109 17.198 11.5 16.698 11.5 16.124V10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>`,
                'add-iot-icon.svg': `<svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <path xmlns="http://www.w3.org/2000/svg" d="M12 8V16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M16 12H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>`,
                'definition-icon.svg': `<svg fill="none" viewBox="0 0 20 20" height="20" width="20" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <path xmlns="http://www.w3.org/2000/svg" d="M3 5V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M17 15V5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M18 19H16C15.448 19 15 18.552 15 18V16C15 15.448 15.448 15 16 15H18C18.552 15 19 15.448 19 16V18C19 18.552 18.552 19 18 19Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M4 19H2C1.448 19 1 18.552 1 18V16C1 15.448 1.448 15 2 15H4C4.552 15 5 15.448 5 16V18C5 18.552 4.552 19 4 19Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M18 5H16C15.448 5 15 4.552 15 4V2C15 1.448 15.448 1 16 1H18C18.552 1 19 1.448 19 2V4C19 4.552 18.552 5 18 5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M4 5H2C1.448 5 1 4.552 1 4V2C1 1.448 1.448 1 2 1H4C4.552 1 5 1.448 5 2V4C5 4.552 4.552 5 4 5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M5 17H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M15 3H5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>`,
                'privot-aria-icon.svg': `<svg fill="none" viewBox="0 0 22 22" height="22" width="22" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M11.5 5H10.5C9.672 5 9 4.328 9 3.5V2.5C9 1.672 9.672 1 10.5 1H11.5C12.328 1 13 1.672 13 2.5V3.5C13 4.328 12.328 5 11.5 5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M11.5 21H10.5C9.672 21 9 20.328 9 19.5V18.5C9 17.672 9.672 17 10.5 17H11.5C12.328 17 13 17.672 13 18.5V19.5C13 20.328 12.328 21 11.5 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M17 11.5V10.5C17 9.672 17.672 9 18.5 9H19.5C20.328 9 21 9.672 21 10.5V11.5C21 12.328 20.328 13 19.5 13H18.5C17.672 13 17 12.328 17 11.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M1 11.5V10.5C1 9.672 1.672 9 2.5 9H3.5C4.328 9 5 9.672 5 10.5V11.5C5 12.328 4.328 13 3.5 13H2.5C1.672 13 1 12.328 1 11.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M16.15 3.91834C16.152 3.92029 16.152 3.92346 16.15 3.92541C16.1481 3.92736 16.1449 3.92736 16.1429 3.92541C16.141 3.92346 16.141 3.92029 16.1429 3.91834C16.1449 3.91639 16.1481 3.91639 16.15 3.91834" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M18.0807 5.84241C18.0826 5.84436 18.0826 5.84753 18.0807 5.84948C18.0787 5.85144 18.0756 5.85144 18.0736 5.84948C18.0717 5.84753 18.0717 5.84436 18.0736 5.84241C18.0756 5.84046 18.0787 5.84046 18.0807 5.84241" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M5.85607 18.0746C5.85803 18.0765 5.85803 18.0797 5.85607 18.0817C5.85412 18.0836 5.85096 18.0836 5.849 18.0817C5.84705 18.0797 5.84705 18.0765 5.849 18.0746C5.85096 18.0726 5.85412 18.0726 5.85607 18.0746" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M3.92639 16.1505C3.92834 16.1525 3.92834 16.1556 3.92639 16.1576C3.92443 16.1595 3.92127 16.1595 3.91932 16.1576C3.91736 16.1556 3.91736 16.1525 3.91932 16.1505C3.92127 16.1486 3.92443 16.1486 3.92639 16.1505" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M3.92541 5.84949C3.92736 5.85144 3.92736 5.85461 3.92541 5.85656C3.92346 5.85852 3.92029 5.85852 3.91834 5.85656C3.91639 5.85461 3.91639 5.85144 3.91834 5.84949C3.92029 5.84754 3.92346 5.84754 3.92541 5.84949" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M5.84924 3.91956C5.85119 3.92151 5.85119 3.92468 5.84924 3.92663C5.84729 3.92858 5.84412 3.92858 5.84217 3.92663C5.84021 3.92468 5.84021 3.92151 5.84217 3.91956C5.84412 3.91761 5.84729 3.91761 5.84924 3.91956" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M18.0817 16.1434C18.0836 16.1454 18.0836 16.1486 18.0817 16.1505C18.0797 16.1525 18.0765 16.1525 18.0746 16.1505C18.0726 16.1486 18.0726 16.1454 18.0746 16.1434C18.0765 16.1415 18.0797 16.1415 18.0817 16.1434" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M16.1578 18.0736C16.1598 18.0756 16.1598 18.0787 16.1578 18.0807C16.1559 18.0826 16.1527 18.0826 16.1508 18.0807C16.1488 18.0787 16.1488 18.0756 16.1508 18.0736C16.1527 18.0717 16.1559 18.0717 16.1578 18.0736" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>`,
                'add-point-icon.svg': `<svg fill="none" viewBox="0 0 21 21" height="21" width="21" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <path xmlns="http://www.w3.org/2000/svg" d="M8.00095 14.999C4.13595 14.999 1.00195 11.866 1.00195 8C1.00195 4.216 4.21795 1 8.00195 1C11.867 1 15 4.134 15 7.999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M18.8869 13.317C16.1829 14.054 14.0529 16.184 13.3159 18.888C13.1829 19.376 12.5149 19.415 12.3549 18.935L9.38188 10.015C9.25188 9.62397 9.62388 9.25197 10.0139 9.38297L18.9339 12.356C19.4139 12.516 19.3749 13.184 18.8869 13.317Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>`,
                'zoom-control-in.svg': `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <g clip-path="url(#clip0_3220_32971)">
                            <g filter="url(#filter0_d_3220_32971)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.75 3.5C12.75 3.08579 12.4142 2.75 12 2.75C11.5858 2.75 11.25 3.08579 11.25 3.5V11.75H3C2.58579 11.75 2.25 12.0858 2.25 12.5C2.25 12.9142 2.58579 13.25 3 13.25H11.25V21.5C11.25 21.9142 11.5858 22.25 12 22.25C12.4142 22.25 12.75 21.9142 12.75 21.5V13.25H21C21.4142 13.25 21.75 12.9142 21.75 12.5C21.75 12.0858 21.4142 11.75 21 11.75H12.75V3.5Z" fill="white"/>
                            </g>
                        </g>
                        <defs>
                            <filter id="filter0_d_3220_32971" x="0.25" y="0.75" width="23.5" height="23.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="1"/>
                                <feComposite in2="hardAlpha" operator="out"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3220_32971"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3220_32971" result="shape"/>
                            </filter>
                            <clipPath id="clip0_3220_32971">
                                <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                        </defs>
                    </svg>`,
                'zoom-control-out.svg': `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <g filter="url(#filter0_d_3220_32976)">
                            <path d="M3 12.5H21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <filter id="filter0_d_3220_32976" x="0.25" y="9.75" width="23.5" height="5.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="1"/>
                                <feComposite in2="hardAlpha" operator="out"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3220_32976"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3220_32976" result="shape"/>
                            </filter>
                        </defs>
                    </svg>`,
                'maptype-control.svg': `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <g filter="url(#filter0_d_3220_32980)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.89863 16.5086L10.0391 10.8242C10.1186 10.4282 10.4283 10.1189 10.8244 10.0399L16.5088 8.90343C16.6727 8.87081 16.842 8.92216 16.9602 9.04031C17.0783 9.15846 17.1297 9.32781 17.097 9.49168L15.9606 15.176C15.8813 15.5718 15.572 15.8811 15.1762 15.9604L9.49187 17.1018C9.3262 17.137 9.15397 17.086 9.03421 16.9663C8.91445 16.8465 8.86343 16.6743 8.89863 16.5086Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
                        </g>
                        <g filter="url(#filter1_d_3220_32980)">
                            <circle cx="13.0003" cy="13.0001" r="9.00375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
                        </g>
                        <defs>
                            <filter id="filter0_d_3220_32980" x="6.1377" y="6.1438" width="13.7188" height="13.719" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="1"/>
                                <feComposite in2="hardAlpha" operator="out"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3220_32980"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3220_32980" result="shape"/>
                            </filter>
                            <filter id="filter1_d_3220_32980" x="1.24658" y="1.24634" width="23.5073" height="23.5076" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="1"/>
                                <feComposite in2="hardAlpha" operator="out"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3220_32980"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3220_32980" result="shape"/>
                            </filter>
                        </defs>
                    </svg>`,
                'x.svg': `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <path d="M15 5L5 15" stroke="#9AA6AC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 5L15 15" stroke="#9AA6AC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>`,
                'Arrow-next.svg': `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <path d="M10 16L14 12L10 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>`,
                'ico-remove.svg': `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <path d="M12.8299 7.16992L7.16992 12.8299" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.8299 12.8299L7.16992 7.16992" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 19V19C5.029 19 1 14.971 1 10V10C1 5.029 5.029 1 10 1V1C14.971 1 19 5.029 19 10V10C19 14.971 14.971 19 10 19Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>`,
                'message-question-checkmark.svg': `<svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <path xmlns="http://www.w3.org/2000/svg" d="M9.68359 9.68482C9.92062 8.66326 10.8511 7.95496 11.8988 7.99844C13.0715 7.93349 14.0768 8.82709 14.1498 9.99928C14.1498 11.5038 11.9989 12.0001 11.9989 13.0005" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M12.1253 15.7518C12.1252 15.8209 12.0692 15.8768 12.0002 15.8767C11.9311 15.8767 11.8752 15.8207 11.8752 15.7517C11.8751 15.6827 11.931 15.6267 12 15.6266C12.0333 15.6266 12.0651 15.6398 12.0886 15.6632C12.1121 15.6867 12.1253 15.7186 12.1253 15.7518" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M4.62666 17.1605C1.94356 13.3308 2.63307 8.08546 6.21477 5.07926C9.79647 2.07305 15.0819 2.30347 18.3884 5.60997C21.6949 8.91646 21.9253 14.2019 18.9191 17.7836C15.9129 21.3653 10.6676 22.0548 6.83785 19.3717L3.99493 20.0034L4.62666 17.1605Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>`,
                'Headphones.svg': `<svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M19 17H18C17.448 17 17 16.552 17 16V11C17 10.448 17.448 10 18 10H19C20.105 10 21 10.895 21 12V15C21 16.105 20.105 17 19 17Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M6 17H5C3.895 17 3 16.105 3 15V12C3 10.895 3.895 10 5 10H6C6.552 10 7 10.448 7 11V16C7 16.552 6.552 17 6 17Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M18.5 10V9.5C18.5 5.91 15.59 3 12 3V3C8.41 3 5.5 5.91 5.5 9.5V10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M12.625 21.25H11.375C10.685 21.25 10.125 20.69 10.125 20V20C10.125 19.31 10.685 18.75 11.375 18.75H12.625C13.315 18.75 13.875 19.31 13.875 20V20C13.875 20.69 13.315 21.25 12.625 21.25Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" d="M13.875 20H16C17.105 20 18 19.105 18 18V17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>`,
                'bell-notifications.svg': `<svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style="${this.styles ? this.styles : ''}">
                        <path xmlns="http://www.w3.org/2000/svg" d="M9.50781 18.0027V18.5149C9.50781 19.1754 9.77032 19.8088 10.2375 20.2756C10.7048 20.7425 11.3384 21.0045 11.9989 21.0039V21.0039C12.6594 21.0042 13.293 20.742 13.7602 20.275C14.2274 19.808 14.4899 19.1745 14.4899 18.5139V18.0027" stroke="#397490" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M17.9489 18.0026C19.0832 18.0026 20.0028 17.083 20.0028 15.9487V15.9487C20.0023 15.3725 19.7735 14.82 19.3665 14.4121L18.0019 13.0485V8.99884C18.0019 5.68375 15.3145 2.99634 11.9994 2.99634V2.99634C8.68434 2.99634 5.99693 5.68375 5.99693 8.99884V13.0485L4.63236 14.4121C4.22534 14.82 3.99655 15.3725 3.99609 15.9487V15.9487C3.99609 16.4934 4.21248 17.0159 4.59765 17.401C4.98283 17.7862 5.50523 18.0026 6.04995 18.0026H17.9489Z" stroke="#397490" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>`,
                '': ``,
                '': ``,
                '': ``,
            }
        }
    }
}
</script>

<style>
    .svg_icons_wrpr {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>