<template>
	<div class="popup_wrpr auth-error-popup">
		<div class="popup_overlay" @click="close"></div>
		<div class="popup__window">
			<div class="popup__window-content w-450">
				<div class="popup__window-header">
					<svg width="62" height="62" viewBox="0 0 62 62" fill="none" class="auth-error-icon">
						<circle cx="30.9986" cy="30.9984" r="22.5094" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></circle>
						<path class="warning" d="M31 44L31 44L31 44" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
						<path class="warning" d="M30.9717 18.0283L31.0283 18.0283L31 37.799" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
					</svg>
					<h2>{{ payload.title }}</h2>
					<button type="button" class="popup__closer"  @click="close">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M15 5L5 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
							<path d="M5 5L15 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
						</svg>
					</button>
				</div>
				<div class="popup__window-body">
					<p class="popup__message" v-html="computedMessage">
					</p>
				</div>
				<div class="popup__window-actions">
					<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
						<span>{{ $translate('close') }}</span>
					</button>
					<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="forgotPassword">
						<span>Reset password</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AuthError',
	props: ['payload'],
	methods: {
		close() {
			this.$emit('close')
		},
		forgotPassword(){
			this.close();
			this.$emit('goto', 'forgotpassword');
		}
	},
	computed: {
		computedMessage(){
			if(this.payload.message.toLowerCase().includes('password')){
				return `
					The password does not meet one or more of the following requirements: <br>
					Minimum string length of 8 characters; <br>
					Presence of lowercase letters (a-z); <br>
					Presence of capital letters (A-Z); <br>
					Presence of Arabic numerals (0-9); <br>
					Non-alphanumeric characters (for example!,?, #, *);
				`
			} else if(this.payload.message.toLowerCase().includes('2 validation errors detected')){
				return `
					Wrong email or password
				`
			} else {
				return this.payload.message
			}
		}
	},
}
</script>

<style>
	.auth-error-popup .popup__window-header {
		align-items: center;
	}

	.auth-error-popup h2 {
		margin-right: auto;
	}

	.auth-error-icon {
		width: 35px; 
		height: 35px; 
		color: var(--graph-red);
		margin-right: 10px;
	}
</style>