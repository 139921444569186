import { XFarm_API_URL } from '@/config/';
import axios from 'axios';
// import response_device_data from '@/assets/json/response_device_data'
export default class xFarm {
    constructor(store) {
        this.store = store;
        this.authToken = null;
        this.companyId = null;
        this.login();
    }

    async login(){
        // let data = {
        //     "username": "demo@xfarm.ag",
        //     "password": "demoaccount0"
        // };
        let data = {
            "username": "info@enismaro.com",
            "password": "enismaro2021"
        };

        let result = await axios.post(`${XFarm_API_URL}/public/v1/auth/login`, data, {
            headers: {
                'Content-Type': 'text/plain',
            }
        }).then(res => this.authToken = res.data.access_token);

        // await this.getDeviceList();
        // await this.getIrrigationZones();
        // await this.getIrrigationAdvice();

        // console.log('xFarm Login:', result);
    }

    async getDeviceList(){
        let result = await axios.get(`${XFarm_API_URL}/private/v1/xsense/devices`, {
            headers: {
                'Authorization': `Bearer ${this.authToken}`,
            }
        }).then(res => res.data);
        
        if(result && result.length) this.companyId = result[0].companyId;

        // console.log('xFarm getDeviceList:', result);
    }

    async getDeviceData(id, params){
        let url = `${XFarm_API_URL}/private/v1/xsense/deviceData?deviceID=${id}`;
        if(params){
            Object.entries(params).forEach(item => {
                url += `&${item[0]}=${item[1]}`;
            });
        }

        let result = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${this.authToken}`,
            }
        }).then(res => res.data);

        // console.log('xFarm getDeviceData:', result);

        return result;
    }

    async getIrrigationZones(){
        let result = await axios.get(`${XFarm_API_URL}/private/v1/irrigation/irrigationZones?companyIds=${this.companyId}&multiFarm=true`, {
            headers: {
                'Authorization': `Bearer ${this.authToken}`,
            }
        }).then(res => res.data);

        console.log('xFarm getIrrigationZones:', result);
    }

    async getIrrigationAdvice(){
        let result = await axios.get(`${XFarm_API_URL}/private/v1/irrigation/irrigationAdvices/?companyIds=${this.companyId}&multiFarm=true&lang=it`, {
            headers: {
                'Authorization': `Bearer ${this.authToken}`,
            }
        }).then(res => res.data);

        console.log('xFarm getIrrigationAdvice:', result);
    }

}