<template>
	<div>
		<slot name="auth-header"></slot>

		<Spinner v-if="loading" />

		<div class="wrapper_main none-padding" style="width:400px;max-width:100%;">
			<main class="content content-center">
				<div class="container">
					<form class="renew-plan" @submit.prevent="forgot">
						<div class="renew-plan-header">
							<h1>{{ setRegPassword ? 'Create a password' : $translate('reset-your-password') }}</h1>
						</div>				
						<div class="renew-plan-form">
							<div class="items-group cards" v-if="!verify">
								<div class="cards__col">
									<div class="el-form">
										<label class="el-form__title">{{ $translate('email') }}</label>
										<input required type="text" class="input" v-model="email">
									</div>
								</div>
								<div class="cards__col mt-15" v-if="errorText">
									<div class="el-form">
										<p style="color: #ff0000;">{{ errorText }}</p>
									</div>
								</div>
							</div>
							<div class="items-group cards" v-else>
								<div class="cards__col">
									<div class="el-form">
										<label class="el-form__title">{{ $translate('code') }}</label>
										<input required type="text" class="input" v-model="code">
									</div>
								</div>
								<div class="cards__col mt-15">
									<div class="el-form">
										<label class="el-form__title">{{ $translate('new-password') }}</label>
										<div class="relative">
											<input required :type="passwordView ? 'text' : 'password'" class="input" v-model="new_password">
											<a class="toggle_password_show_btn" @click.stop="passwordView = !passwordView" :class="{ 'active':passwordView }">
												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 488.85 488.85" style="enable-background:new 0 0 488.85 488.85;" xml:space="preserve">
													<g>
														<path d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2   s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025   c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3   C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7   c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"/>
													</g>
												</svg>
											</a>
										</div>
									</div>
								</div>
							</div>
							<button type="submit" class="btn btn-primary btn-standart mt-30">
								{{ verify ? $translate('submit-code') : $translate('send-code')  }}
							</button>
						</div>
					</form>
				</div>
			</main>
		</div>

		<slot name="auth-footer"></slot>
	</div>	
</template>

<script>
import { Auth } from 'aws-amplify';
import Base from '@/components/base';
export default {
	components: {
		...Base
	},
	data() {
		return {
			email: '',
			code: '',
			new_password: '',
			verify: false,
			passwordView: false,
			loading: false,
			setRegPassword: false,
			errorText: null,
		}
	},
	methods: {
		async forgot() {
			this.errorText = null;
			this.loading = true;
			// Send confirmation code to user's email
			if(this.verify) this.forgotSubmit();
			else {
				try {
					await Auth.forgotPassword(this.email);
					this.verify = true;
				} catch(err) {
					console.log(err);

					if(err.toString().includes('registered/verified')){
						this.errorText = 'Cannot reset password for the user as there is no registered/verified email';
					}
				} finally {
					this.loading = false;
				}
			}
		},

		async forgotSubmit() {
			this.errorText = null;
			// Collect confirmation code and new password, then
			try {
				await Auth.forgotPasswordSubmit(this.email, this.code, this.new_password);
				await Auth.signIn(this.email, this.new_password);

				const user = await Auth.currentAuthenticatedUser();
				await Auth.updateUserAttributes(user, {
					'custom:pl': ''+this.new_password
				});
			} catch(err) {
				console.log(err)
			} finally {
				this.loading = false
			}
		}
	},
	mounted(){
		setTimeout(() => {
			if(this.$route.query["reset-password"]){
				this.setRegPassword = true;
				this.email = this.$route.query["reset-password"];
			}
		}, 500);
	}
}
</script>

<style>

</style>