import Card from './Card.vue'
import Spinner from './Spinner.vue'
import NotificationPopup from './NotificationPopup.vue'
import Gallery from './Gallery.vue'
import Dropdown from './Dropdown.vue';
import DropdownAdvanced from './DropdownAdvanced.vue';
import DropdownSimple from './DropdownSimple.vue';
import DropdownEvent from './DropdownEvent.vue';
import DropdownDynamic from './DropdownDynamic.vue';
import AuthError from './AuthError.vue'
import DatePicker from './DatePicker.vue'
import TimePicker from './TimePicker.vue'
import DeviceIcon from './DeviceIcon.vue'
import Pagination from './Pagination.vue'
import FooterTabsControl from './FooterTabsControl.vue'
import TableAdvanced from './TableAdvanced.vue'
import PopupPagination from './PopupPagination.vue'
import TableMessages from './TableMessages.vue'
import YearPicker from './YearPicker.vue'
import SvgIcons from './SvgIcons.vue'

export default {
    Card,
    Spinner,
    NotificationPopup,
    Gallery,
    Dropdown,
    DropdownAdvanced,
    DropdownSimple,
    DropdownDynamic,
    AuthError,
    DatePicker,
    TimePicker,
    DeviceIcon,
    Pagination,
    FooterTabsControl,
    TableAdvanced,
    PopupPagination,
    DropdownEvent,
    TableMessages,
    YearPicker,
    SvgIcons,
}