import fr from './fr';
import en from './en';
import gm from './gm';
import it from './it';
import sp from './sp';
import pg from './pg';

export default {
    fr,
    en,
    gm,
    it,
    sp,
    pg
}