<template>
	<div>
		<slot name="auth-header"></slot>	

		<Spinner v-if="loading" />

		<div class="wrapper_main none-padding" style="width:400px;max-width:100%;">
			<main class="content content-center">
				<div class="container">
					<form class="renew-plan" @submit.prevent="register">
						<div class="renew-plan-header">
							<!-- <h1>
								{{ isVerify ? $translate('verify-your-account') : $translate('create-an-account') }} -->
							<h1 style="line-height: initial;">
								<!-- {{ isVerify ? 'Verify your account' : 'Create an Account' }} -->
								{{ isVerify ? 'Admin will confirm your account within 24 hours' : 'Create an Account' }}
							</h1>
						</div>				
						<div class="renew-plan-form">
							<div class="items-group cards" v-if="!isVerify">
								<div class="cards__col">
									<div class="el-form">
										<label class="el-form__title">{{ $translate('full-name') }}</label>
										<input required type="text" class="input" v-model="userAuth.full_name">
									</div>
								</div>
								<div class="cards__col mt-15">
									<div class="el-form">
										<label class="el-form__title">{{ $translate('email') }}</label>
										<input required type="text" class="input" v-model="userAuth.email">
									</div>
								</div>
								<div class="cards__col mt-15">
									<div class="el-form">
										<label class="el-form__title flex items-center">
											{{ $translate('password') }}
										</label>
										<div class="relative">
											<input required :type="passwordView.pw ? 'text' : 'password'" class="input" v-model="userAuth.pw">
											<a class="toggle_password_show_btn" @click.stop="passwordView.pw = !passwordView.pw" :class="{ 'active':passwordView.pw }">
												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 488.85 488.85" style="enable-background:new 0 0 488.85 488.85;" xml:space="preserve">
													<g>
														<path d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2   s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025   c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3   C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7   c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"/>
													</g>
												</svg>
											</a>
										</div>
									</div>
								</div>
								<div class="cards__col mt-15">
									<div class="el-form">
										<label class="el-form__title flex items-center">
											{{ $translate('confirm-password') }}
										</label>
										<div class="relative">
											<input required :type="passwordView.pw2 ? 'text' : 'password'" class="input" v-model="userAuth.pw2">
											<a class="toggle_password_show_btn" @click.stop="passwordView.pw2 = !passwordView.pw2" :class="{ 'active':passwordView.pw2 }">
												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 488.85 488.85" style="enable-background:new 0 0 488.85 488.85;" xml:space="preserve">
													<g>
														<path d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2   s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025   c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3   C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7   c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"/>
													</g>
												</svg>
											</a>
										</div>
									</div>
								</div>
							</div>
							<!-- <div class="items-group cards" v-else>
								<div class="cards__col">
									<div class="el-form">
										<label class="el-form__title">{{ $translate('enter-the-verification-code') }}</label>
										<input required type="text" class="input" v-model="userAuth.code">
									</div>
								</div>
							</div> -->
							<button type="submit" class="btn btn-primary btn-standart mt-30" v-if="!isVerify">
								{{ isVerify ? 'Submit' : 'Sign Up' }}
							</button>
						</div>
					</form>
					<div style="display: flex;justify-content: center;" v-if="isVerify">
						<button type="submit" class="btn btn-primary btn-standart" @click="refreshPage">Go to Login</button>
					</div>
				</div>
			</main>
		</div>

		<slot name="auth-footer"></slot>
	</div>
</template>

<script>
import { Auth } from 'aws-amplify';
import Base from '@/components/base';
export default {
	components: {
		...Base
	},
	props: ['confirmEmail', 'authBridgeInfo'],
	data() {
		return {
			verify: false,
			userAuth: {
				full_name: '',
				email: '',
				pw: '',
				pw2: '',

				code: '',	
			},
			loading: false,
			passwordView: {
				pw: false,
				pw2: false,
			}
		}
	},
	computed: {
		isVerify() {
			return this.verify || this.confirmEmail
		}
	},
	methods: {
		emitError(
			event = 'confirmPasswordFailure', 
			message = `
				The password does not meet one or more of the following requirements:
				Minimum string length of 8 characters;
				Presence of lowercase letters (a-z)
				Presence of capital letters (A-Z)
				Presence of Arabic numerals (0-9)
				Non-alphanumeric characters (for example!,?, #, *)
			`
		) {
			const ev = {
				payload: {
					event,
					data: {
						message
					}
				}
			}
			this.$emit('error', ev);
			this.loading = false;
		},
		async register() {
			this.loading = true;

			if(this.isVerify) this.verifyEmail()
			else {
				if(this.userAuth.pw !== this.userAuth.pw2) {
					this.emitError();
					return
				}
				try {
					await Auth.signUp({
						username: this.userAuth.email,
						password: this.userAuth.pw,
						attributes: {
							email: this.userAuth.email,
							'custom:full_name': this.userAuth.full_name,
							'custom:pl': ''+this.userAuth.pw.length
						}
					});
					this.verify = true;
				} catch(err) {
					console.log(err)
				} finally {
					this.loading = false;
				}
			}
			
		},

		async verifyEmail() {
			if(this.authBridgeInfo)  {
				let { login, pw } = this.authBridgeInfo;
				this.userAuth.email = login;
				this.userAuth.pw = pw;
			}
			try {
				await Auth.confirmSignUp(this.userAuth.email, this.userAuth.code);
				await Auth.signIn(this.userAuth.email, this.userAuth.pw);
			} catch (error) {
				console.log('error confirming sign up', error);
				this.emitError('verifyEmail_failed', 'Invalid verification code provided');
				console.log(this.userAuth);
			} finally {
				this.loading = false;
			}
		},
		refreshPage(){
			window.location.reload();
		}
	}
}
</script>