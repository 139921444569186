<template>
    <div class="categories dropdown-wrapper flex-auto" v-click-outside="closeMe">
        <button 
            type="button" 
            class="btn btn-standart dropdown-btn" 
            :class="[{ active: open }, btnClass]" 
            @click="toggleMe"
        >
            <span class="active-categories">{{ getCurrent.name || 'Select Option' }}</span>
            <span><img src="@/assets/img/Arrow-down.svg"></span>
        </button>

        <div class="categories__dropdown dropdown-content" :class="{active: open}">
            <ul>
                <li v-for="option in options" :key="option.id" @click="change(option)">
                    <span>{{ option.name }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropdownAdvanced',
    props: ['options', 'current', 'name', 'shadow'],
	model: {
      	prop: 'current',
  	},
    data() {
        return {
            open: false,
        }
    },
    computed: {
        btnClass() {
            return this.shadow ? 'btn-w-shadow' : 'none-shadow'
        },
		getCurrent() {
			return this.options.find(o => o.id === this.current) || {}
		}
    },
    methods: {
        change(option) {
            const event = {
                target: {
                    value: option,
                    name: this.name
                }
            }
            this.$emit('change', event);
			this.$emit('input', option.id);
            this.closeMe();
        },
        closeMe() {
            this.open = false
        },
        toggleMe() {
            this.open = !this.open
        }
    }
}
</script>