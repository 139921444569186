<template>
	<div class="gallery-popup">
		<div class="gallery-popup-overlay" @click="close">
			<div class="loader" v-if="loading"></div>
		</div>
		<div class="gallery-popup-hero" v-if="media && media.length">
			<img :src="media[current].url" @load="loaded" ref="hero-image" class="hero-image">
		</div>
		<div class="popup-buttons">
			<a class="btn btn-sm btn-primary gallery-btn" @click="prev">{{ $translate('prev') }}</a>
			<div class="gallery-current btn btn-sm">
				{{ `${current + 1} of ${media.length}` }}
			</div>
			<a class="btn btn-sm btn-primary gallery-btn" @click="next">{{ $translate('next') }}</a>
		</div>
		<a class="gallery-popup-closer btn-sm" @click="close">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M15 5L5 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
				<path d="M5 5L15 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
			</svg>
		</a>
	</div>
</template>

<script>
export default {
	name: 'Gallery',
	props: ['media', 'currentImage'],
	data() {
		return {
			current: 0,
			loading: false,
		}
	},
	created() {
		if(this.currentImage) this.current = this.currentImage
	},
	methods: {
		next() {
			this.loading = true;
			this.$refs['hero-image'].classList.add('loading');
			if(this.current + 2 < this.media.length) this.current++
			else this.current = 0
		},
		prev() {
			this.loading = true;
			this.$refs['hero-image'].classList.add('loading');
			if(this.current > 0) this.current--
			else this.current = this.media.length - 1
		},
		loaded(ev) {
			//console.log('loaded');
			setTimeout(() => {
				this.$refs['hero-image'].classList.remove('loading');
				this.loading = false;
			}, 600)
			
		},
		close() {
			this.$emit('close');
		}
	}
}
</script>

<style>
.gallery-popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	background: rgb(0 0 0 / 75%);

	transform: translateY(100%);
	animation: gallery-open 1 0.3s ease forwards;
}

.gallery-popup-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	z-index: 0;
}

@keyframes gallery-open {
	to {
		transform: none
	}
}

.gallery-popup-hero {
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	pointer-events: none;
}

.gallery-popup-hero .hero-image {
	display: block;
	max-width: 100%;
	max-height: 100vh;
	object-fit: cover;
	object-position: center;
	transition: all 0.3s ease;
	opacity: 1;
	transform: translateY(0);
}

.hero-image.loading {
	opacity: 0;
	transform: translateY(-100%);
}

.popup-buttons {
	position: absolute;
	bottom: 0;
	z-index: 1;
	display: flex;
	left: 0;
	right: 0;
	justify-content: center;
	align-items: center;
	padding: 5px 25px;
	color: #fff;
	z-index: 2;
}

.gallery-btn {
	margin: 0 5px;
}

.gallery-current {
	text-transform: lowercase;
}

.gallery-popup-closer {
	position: absolute;
	top: 0;
	right: 0;
	color: #fff;
	background: rgb(0 0 0 / 25%);
	border-radius: 0;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loader {
	color: #26a7e1;
	font-size: 15px;
	margin: 0;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	text-indent: -9999em;
	animation: load 1.3s infinite linear;
	transform: translateZ(0) translateX(-50%) translateY(-50%);
	position: absolute;
	left: 50%;
	top: 50%;
}

@keyframes load {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

</style>