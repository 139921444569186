export const tutorialConfig = [
    // {
    //     id: 0,
    //     title: 'IOT Dashboard',
    //     text: 'Registered user lands on the IOT dashboard where he sees all devices onboarded on his platform.',
    //     path: '/',
    // },
    // {
    //     id: 1,
    //     title: 'Devices',
    //     text: 'Text for /devices page',
    //     path: '/devices'
    // },
    // {
    //     id: 2,
    //     title: 'Products',
    //     text: 'Text for /products page',
    //     path: '/products'
    // },
    // {
    //     id: 3,
    //     title: 'Categories',
    //     text: 'Text for /categories page',
    //     path: '/categories'
    // },

    {
        text: [
            {
                element: '.tutorial_tooltip_1',
                text: `Welcome to Enismaro! Start adding an IOT device`
            }
        ],
        path: '/tutorial/',
    },
    {
        text: [
            {
                element: '.tutorial_popup_tooltip_1',
                text: `Select category of your device`
            }
        ],
        path: '/tutorial/?popup=add-device',
    },
    {
        text: [
            {
                element: '.tutorial_popup_tooltip_2',
                text: `2. Add your IOT!`
            }
        ],
        path: '/tutorial/?popup=add-device',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_categories',
                text: `Here you can search for your devices by category`
            }
        ],
        path: '/tutorial/',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_link_devices',
                text: `Here you can find a list of all connected devices with basic information for each one.`
            }
        ],
        path: '/tutorial/devices',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_1',
                text: `In this section an overview of information about your device is shown`
            }
        ],
        path: '/tutorial/devices/tutorial-device?tab=overview',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_2',
                text: `1. Through the "schedule" function you can plan - for each IOT device - a time period within which to receive information or alerts.`
            },
            {
                element: '.tutorial_tooltip_3',
                text: `2.You can set a defined period (weekly, monthly, single day)`
            },
            {
                element: '.tutorial_tooltip_4',
                text: `3. Remember! Warnings will not be generated during the shutdown period.`
            },
        ],
        path: '/tutorial/devices/tutorial-device?tab=schedule',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_1',
                text: `In this section you can set up rules for each of your IOT devices`
            },
        ],
        path: '/tutorial/rules/add?tab=behavior',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_link_products',
                text: `1. In this section you can view all the products you have created on Enismaro.`
            },
            {
                element: '.tutorial_tooltip_1',
                text: `2. To create a new product, click on "Add product".`
            },
            {
                element: '.tutorial_tooltip_2',
                text: `3. Here you can add basic product information`,
                class: 'with_hidden_symbol'
            },
            {
                element: '.tutorial_tooltip_3',
                text: `4. Processing information here`,
                class: 'with_hidden_symbol'
            },
            {
                element: '.tutorial_tooltip_4',
                text: `5. Production site information`,
                class: 'with_hidden_symbol'
            },
            {
                element: '.tutorial_tooltip_5',
                text: `6. And associate the product with one or more IOT devices`,
                class: 'with_hidden_symbol'
            },
            {
                element: '.tutorial_tooltip_6',
                text: `7. Which field to associate it with`,
                class: 'with_hidden_symbol'
            },
            {
                element: '.tutorial_tooltip_7',
                text: `8. Finally, you can select which of these pieces of information to link to the product QR code`,
                class: 'with_hidden_symbol'
            },
        ],
        path: '/tutorial/products/add?tab=info',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_link_categories',
                text: `1. In this section you can associate a linked product with another previously created product.`
            },
            {
                element: '.tutorial_tooltip_parent_1',
                text: `2. you can define the type of connection between the two products`
            },
            {
                element: '.tutorial_tooltip_parent_2',
                text: `3. In the Category section you can create one or more product categories and include one or more existing products in it.`
            },
        ],
        path: '/tutorial/products/add?tab=parent',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_7',
                text: `1. The QR code generation feature allows you to create an integrated QR code with all the product information.
                product information.`
            },
            {
                element: '.tutorial_tooltip_code-settings_1',
                text: `2. You can select which type of information you want to embed in the QR code.`
            },
        ],
        path: '/tutorial/products/add?code-settings&tab=code-settings',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_link_dispatch',
                text: `1. In this section you can create a shipment by specifying the products you intend to send to the recipient.`
            },
            {
                element: '.tutorial_tooltip_1',
                text: `2.You can enter a number of basic information, such as shipment ID and document ID`
            },
            {
                element: '.tutorial_tooltip_2',
                text: `3.Here you can select the company to which you want to send the product.`,
                class: 'with_hidden_symbol'
            },
            {
                element: '.tutorial_tooltip_3',
                text: `4. Here you can select the products you wish to ship`,
                class: 'with_hidden_symbol'
            },
            {
                element: '.tutorial_tooltip_4',
                text: `5. Here you can add contractual conditions related to your shipment.`,
                class: 'with_hidden_symbol'
            },
        ],
        path: '/tutorial/create-dispatch?tab=info',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_link_incoming-dispatch',
                text: `Here you can view the list of incoming shipments`,
            },
            {
                element: '.tutorial_tooltip_link_transaction-history',
                text: `Here is your shipment history`,
            },
        ],
        path: '/tutorial/incoming-dispatch',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_link_farm-map',
                text: `1.Through this section, you can design your fields and fences or indicate the presence of animals at your farm`,
            },
        ],
        path: '/tutorial/farm-map',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_link_fields-list',
                text: `Here you can view the list of your fields with their information`,
            },
        ],
        path: '/tutorial/fields-list',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_link_actions-list',
                text: `1. In this section you can add and keep track of all the activities carried out at your company`,
            },
            {
                element: '.tutorial_tooltip_link_reports',
                text: `2. In the "reports" section you will have a scanned version of the campaign logbook. You can view the information in it and export the entire logbook.`,
            },
        ],
        path: '/tutorial/actions-list',
    },
    {
        text: [
            {
                element: '.tutorial_tooltip_link_teams',
                text: `1. In this section you can create your user groups.`,
            },
            {
                element: '.content__header-right',
                text: `2. Here you can add a new user to a group`,
            },
            // {
            //     element: '.',
            //     text: `3. Here you can enable the read-only/edit profile for each user.`,
            // },
            // {
            //     element: '.',
            //     text: `4.The profile enabled in this way can be adjusted in a precise time frame via the calendar function`,
            // },
        ],
        path: '/tutorial/teams',
    },
]

export const tutorialRoutes = [
    {
        path: '/tutorial/',
        name: 'Dash Tutorial',
        component: () =>
            import ('../views/Dash.vue'),
        meta: { tutorialRoute: true },
    },
    {
        path: '/tutorial/devices',
        name: 'Devices Tutorial',
        component: () =>
            import ('../views/Devices.vue'),
        meta: { tutorialRoute: true },
    },
    {
        path: '/tutorial/devices/:id',
        name: 'DevicesOverview Tutorial',
        props: true,
        component: () =>
            import ('../views/tutorials/DevicesOverview.vue'),
        meta: { tutorialRoute: true },
    },
    {
        // ?tab=behavior
        path: '/tutorial/rules/add',
        name: 'RulesAdd Tutorial',
        component: () =>
            import ('../views/rules/RulesAdd'),
        meta: { tutorialRoute: true },
    },
    {
        path: '/tutorial/products/add',
        name: 'SingleProductAdd Tutorial',
        props: true,
        component: () =>
            import ('../views/products/Add.vue'),
        meta: { tutorialRoute: true },
    },
    {
        path: '/tutorial/create-dispatch',
        name: 'CreateDispatch Tutorial',
        component: () =>
            import ('../views/dispatch/CreateDispatch.vue'),
        meta: { tutorialRoute: true },
    },
    {
        path: '/tutorial/incoming-dispatch',
        name: 'IncomingDispatch Tutorial',
        component: () =>
            import ('../views/incomingDispatch/IncomingDispatch.vue'),
        meta: { tutorialRoute: true },
    },
    {
        path: '/tutorial/farm-map',
        name: 'FarmMap Tutorial',
        component: () =>
            import ('../views/farmMap/FarmMap.vue'),
        meta: { tutorialRoute: true },
    },
    {
        path: '/tutorial/fields-list',
        name: 'FieldsPaddockList Tutorial',
        component: () =>
            import ('../views/FieldsPaddockList.vue'),
        meta: { tutorialRoute: true },
    },
    {
        path: '/tutorial/actions-list',
        name: 'ActionsList Tutorial',
        component: () =>
            import ('../views/actionsList/ActionsList.vue'),
        meta: { tutorialRoute: true },
    },
    {
        path: '/tutorial/teams',
        name: 'Teams Tutorial',
        component: () =>
            import ('../views/Teams/Teams.vue'),
        meta: { tutorialRoute: true },
    },
]