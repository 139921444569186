<template>
	<div id="app" v-if="renderComponent">

		<router-view v-if="isLegal" ></router-view>
		<!-- forgotpassword signup -->
		<amplify-authenticator v-else>

			<Header v-if="authState === 'signedin' && userData" @toggle="toggleTutorial">
				<!-- <Tutorial v-if="tutorialState" @toggle="toggleTutorial"/> -->
			</Header>

			<Tutorial v-if="tutorialState" @toggle="toggleTutorial"/>

			<Sidebar v-if="authState === 'signedin' && userData"
				:sidebarState="sidebarState" 
				@toggle="toggleSidebar"  
			/>

			<transition name="fade" mode="out-in">
				<router-view v-if="authState === 'signedin' && userData"></router-view>
			</transition>

			<Login slot="sign-in" @goto="goto" @bridge="authBridge" >
				<AuthHeader slot="auth-header" >
					<p>{{ $translate('new-to-enismaro?') }}</p>
					<a @click="goto('signup')" class="btn btn-primary btn-standart-2 ml-20" style="cursor: pointer">{{ $translate('create-an-account') }}</a> 
				</AuthHeader>
				<Footer slot="auth-footer"/>
			</Login>

			<Register slot="sign-up" @goto="goto" @error="listen" :confirmEmail="confirmEmail" :authBridgeInfo="authBridgeInfo" >
				<AuthHeader slot="auth-header" >
					<p>{{ $translate('already-have-an-account?') }}</p>
					<a @click="goto('signin')" class="btn btn-primary btn-standart-2 ml-20" style="cursor: pointer">{{ $translate('login') }}</a> 
				</AuthHeader>
				<Footer slot="auth-footer" />
			</Register>

			<ForgotPassword slot="forgot-password" @goto="goto" >
				<AuthHeader slot="auth-header">
					<p>{{ $translate('new-to-enismaro?') }}</p>
					<a @click="goto('signup')" class="btn btn-primary btn-standart-2 ml-20" style="cursor: pointer">{{ $translate('create-an-account') }}</a> 
				</AuthHeader>
				<Footer slot="auth-footer" />
			</ForgotPassword>		

		</amplify-authenticator>

		<NotificationPopup v-if="ui.notificationPopup.show" />
		<AuthError v-if="errorInfo" :payload="errorInfo" @close="clearError" @goto="goto" />
	</div>
</template>

<script>
import { API, Storage, Auth, Hub } from 'aws-amplify';
import {loadStripe} from '@stripe/stripe-js';

import { 
	createUser,
	createCompany,
	createFarm, 
	updateUser,
	updateRules,
	createAlert,
} from '@/graphql/mutations';

import {
	onUpdateUser,
	onUpdateCompany,
	onUpdateDispatch,
	onDeleteDispatch
} from '@/graphql/subscriptions';

import { devicesDataCategories } from "@/config";

import { 
	userByUsername,
	getCompany,
	listCategories,
	getDevice,
} from '@/graphql/queries';

import { 
	onAuthUIStateChange,
} from '@aws-amplify/ui-components';

import Parts from '@/components/parts';
import Base from '@/components/base';

import Login from '@/views/auth/Login.vue';
import Register from '@/views/auth/Register.vue';
import ForgotPassword from '@/views/auth/ForgotPassword.vue';

import { filterAuthEvents } from '@/helpers'

export default {
	name: 'App',
	components: {
		Login,
		Register,
		ForgotPassword,
		...Parts,
		...Base
	},
	async created() {
		this.subscribe();

		this.unsubscribeAuth = onAuthUIStateChange(async(authState, authData) => {
			this.$store.commit('SET_AUTH', { authState, authData })

			if(authState === 'signedin') {
				await this.getAllData();
				// await this.checkAlerts();
			}
		});
		
		Hub.listen('auth', this.listen);

		// let icon = await API.get('apid24bd84c', '/items');
		// let stripe = await API.post('apid24bd84c', '/items');
		// console.log('stripe:', stripe);

		try {
			let stripe2 = await API.get('stripeapi', '/stripe');
			console.log('stripe2:', stripe2);

			const stripe = await loadStripe(stripe2.settings.stripe_public_key);
			// this.$store.commit('SET_STRIPE', stripe);
			// window._stripe = stripe;

			// stripe
			// 	.createPaymentMethod({
			// 		type: 'card',
			// 		card: stripe.elements().create('card'),
			// 		billing_details: {
			// 			name: 'Jenny Rosen',
			// 		},
			// 	})
			// 	.then(function(result) {
			// 		console.log(result);
			// 	});
		} catch(err) {
			console.log(err);
		}

		if(this.$route.query["reset-password"]){
			this.goto('forgotpassword');
		}
	},

	data() {
		return {
			tutorialState: false,
			sidebarState: false,
			unsubscribeAuth: undefined,
			errorInfo: null,
			confirmEmail: false,
			authBridgeInfo: null,
			iotDataLoading: false,
			categories: devicesDataCategories,
			renderComponent: true,
		}
	},
	computed: {
		authState() {
			return this.$store.state.authState
		},
		authData() {
			return this.$store.state.authData
		},
		userData() {
			return this.$store.state.userData
		},
		isLegal() {
			return ['Terms','Privacy','Security','QRMobile', 'QR Dispatch'].includes(this.$route.name)
		},

		ui() {
			return this.$store.state.ui
		},

		activeLang() {
			return this.$store.getters.lang;
		}
	},
	watch: {
		activeLang(newVal, oldVal) {
			if (oldVal) window.location.reload();
		}
	},
	methods: {
		rerenderApp() {
			window.location.reload();
			// this.renderComponent = false;
			// this.$nextTick().then(() => {
			// 	this.renderComponent = true;
			// });
		},
		toggleTutorial() {
			this.tutorialState = !this.tutorialState
		},
		authBridge(ev) {
			this.authBridgeInfo = ev;
		},
		clearError() {
			this.errorInfo = null
		},
		listen(ev) {
			this.errorInfo = filterAuthEvents(ev.payload);

			if(
				ev &&
				ev.payload &&
				ev.payload.data &&
				ev.payload.data.message === 'User is not confirmed.'
			) {
				this.confirmEmail = true;
				this.goto('signup');
			}
		},
		goto(state) {
			Hub.dispatch('UI Auth', {
				event: 'AuthStateChange',
				message: state,
			});
		},
		toggleSidebar() {
			this.sidebarState = !this.sidebarState;
			document.body.classList.toggle('sidebar-collapsed');
		},
		async initFarm(){
            if(this.$store.state.companyData && this.$store.state.companyData.id){
                let farm = await this.$store.dispatch('GET_FARM');
                if(!farm){
                    await API.graphql({
                        query: createFarm,
                        variables: {
                            input: {
                                companyID: this.$store.state.companyData.id
                            }
                        }
                    });

                    await this.$store.dispatch('GET_FARM');
                }
                await this.$store.dispatch('GET_PADDOCKS');
            } else {
                setTimeout(() => {
                    this.initFarm();
                }, 1000);
            }
        },
		async getAllData() {
			// User
			let user = await API.graphql({ 
				query: userByUsername,
				variables: { username: this.authData.username } 
			})

			let userData = user.data.userByUsername.items.pop();

			if(!userData) {
				user = await API.graphql({ 
					query: userByUsername,
					variables: { username: this.authData.attributes.email } 
				})
				userData = user.data.userByUsername.items.pop();
			}

			if(userData && userData.temporary_password && userData.temporary_password.length) {
				await API.graphql({
					query: updateUser,
					variables: {
						input: {
							id: userData.id,
							temporary_password: ''
						}
					}
				})
			}

			if(!userData) {
				userData = {
					fullname: this.authData.attributes['custom:full_name'], 
					username: this.authData.username 
				}
				const result = await API.graphql({
					query: createUser,
					variables: { input: userData }
				})
				userData = result.data.createUser;
			}

			let userUpdateFromCognito = {};
			if(!userData.fullname && this.authData.attributes['custom:full_name']){
				userUpdateFromCognito.fullname = this.authData.attributes['custom:full_name'];
			}
			if(!userData.email && this.authData.attributes['email']){
				userUpdateFromCognito.email = this.authData.attributes['email'];
			}

			if(Object.entries(userUpdateFromCognito).length){
				let userDataUpdated = await API.graphql({
					query: updateUser,
					variables: {
						input: {
							id: userData.id,
							...userUpdateFromCognito,
						}
					}
				})

				userData = userDataUpdated.data.updateUser;

				this.$store.commit('SET_USERDATA', userData);
			}

			// Company
			let companyData = null;
			let userCompanyId = userData.userCompanyId;

			if(!userCompanyId) {
				companyData = {
					company_name: 'My New Company',
					companyUserId: userData.id,
				}
				const company = await API.graphql({
					query: createCompany,
					variables: {
						companyUserId: userData.id,
						input: companyData 
					}
				});
				companyData = company.data.createCompany;
				userCompanyId = companyData.id;
				userData = await API.graphql({
					query: updateUser,
					variables: {
						input: {
							id: userData.id,
							userCompanyId: companyData.id
						}
					}
				})
				userData = userData.data.updateUser
			}

			const company = await API.graphql({
				query: getCompany,
				variables: {
					id: userCompanyId
				}
			})
			companyData = company.data.getCompany;

			console.log('App User company:', company);

			this.$store.commit('SET_USERDATA', userData);
			this.$store.commit('SET_COMPANYDATA', companyData);
			
			await this.$store.dispatch('GET_DEVICES');
			await this.$store.dispatch('GET_PRODUCTS');
			await this.$store.dispatch('GET_CATEGORIES');
			await this.$store.dispatch('GET_DISPATCHES');
			await this.$store.dispatch('GET_GROUPS');
			await this.initFarm();
			await this.$store.dispatch('GET_FARMACTIONS');
			await this.$store.dispatch('GET_RULES');
			await this.$store.dispatch('GET_ALERTS');
			// await this.$store.dispatch('CENTAUR_LOGIN');
		
			this.iotDataLoading = true;
			await this.$store.dispatch('GET_IOT_DATA');
			this.iotDataLoading = false;
			
			// Profile Photo
			if(userData.profile_photo) {
				this.getProfilePhoto(userData.profile_photo);
			}
		},

		async getProfilePhoto(key) {
			let keyPhoto = key;
			const profile_photo = key ? await Storage.get(keyPhoto) : null;
			this.$store.commit('SET_PROFILEPHOTO', profile_photo);
		},

		subscribe() {
			let $this = this;
			API.graphql({ query: onUpdateUser })
				.subscribe({
					next: (eventData) => {
						let user = eventData.value.data.onUpdateUser;
						let userID = this.$store.state.userData.id
						if(user.id!==userID){
							console.log('user updated:', user);
							return
						}
						const { profile_photo } = user;
						if($this.userData && $this.userData.profile_photo !== profile_photo) {
							$this.getProfilePhoto(profile_photo);
						}
						this.$store.commit('SET_USERDATA', user);
					}
				});

			API.graphql({ query: onUpdateCompany })
				.subscribe({
					next: (eventData) => {
						let company = eventData.value.data.onUpdateCompany;
						let companyID = this.$store.state.companyData.id
						if(company.id!==companyID){
							return 
						}
						console.log("update company",company);

						this.$store.commit('SET_COMPANYDATA', company);
					}
				});
			API.graphql({ query: onUpdateDispatch })
				.subscribe({
					next: (eventData) => {
						let dispatch = eventData.value.data.onUpdateDispatch;
						let companyID=this.$store.state.companyData.id
						if(dispatch.companiesStatus.findIndex(c=>c.id==companyID)!==-1){
							this.$store.commit('UPDATE_ITEM', { field:'dispatches', item:dispatch});
							if(this.$router.history.current.params.id==dispatch.id){
								this.$store.dispatch('GET_ACTIVE_DISPATCH',{id:dispatch.id});
							}

						}
					}
				});
			API.graphql({ query: onDeleteDispatch })
				.subscribe({
					next: (eventData) => {
						let dispatch = eventData.value.data.onDeleteDispatch;
						console.log('delete',dispatch);
						let companyID=this.$store.state.companyData.id
						if(this.$store.state.dispatches.find(d=>d.id==dispatch.id)){
							this.$store.commit('REMOVE_ITEM', { field:'dispatches', id:dispatch.id});


						}
						// }
					}
				});

			// onCreateProduct
			// onUpdateProduct
			// onDeleteProduct
			// onCreateCategory
			// onUpdateCategory
			// onDeleteCategory	
		},

		checkAlerts(){
            let rules = this.$store.state.rules.filter(item => item.status === "enabled");
            let devices = this.$store.state.devices;
			let nowDate = new Date();
			let days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
			let dayName = days[new Date().getDay()];

			let checkDataValue = function(behaviour, deviceData){
				let comparisonValue = 0;

				if(behaviour.behavior === 'single') comparisonValue = deviceData[deviceData.length-1].value;

				if(behaviour.behavior === 'min') comparisonValue = Math.min(...deviceData.map(item => item.value));

				if(behaviour.behavior === 'max') comparisonValue = Math.max(...deviceData.map(item => item.value));

				if(behaviour.behavior === 'average'){
					const sum = deviceData.map(item => item.value).reduce((a, b) => a + b, 0);
					comparisonValue = (sum / deviceData.map(item => item.value).length) || 0;
				}

				switch (behaviour.operator) {
					case 'equal':
						if(comparisonValue === behaviour.value) return true;
					case 'not_equal':
						if(comparisonValue !== behaviour.value) return true;
					case 'greater_than':
						if(comparisonValue > behaviour.value) return true;
					case 'less_than':
						if(comparisonValue < behaviour.value) return true;
					case 'equal_or_grater_than':
						if(comparisonValue >= behaviour.value) return true;
					case 'equal_or_less_then':
						if(comparisonValue <= behaviour.value) return true;
					default:
						return false;
				}
			};

			rules.forEach(async ruleElement => {
				if(ruleElement.behavior.observation_period === 'periodic'){
					console.log('ruleElement periodic:', ruleElement);
					let deviceDataFilterCategory = null;

					if(
						ruleElement.behavior.temporal_settings === "daily" && 
						+ruleElement.behavior.time_of_observation_from.split(':')[0]*60 + +ruleElement.behavior.time_of_observation_from.split(':')[1] <= nowDate.getHours()*60 + nowDate.getMinutes() && 
						+ruleElement.behavior.time_of_observation_to.split(':')[0]*60 + +ruleElement.behavior.time_of_observation_to.split(':')[1] >= nowDate.getHours()*60 + nowDate.getMinutes()
					){
						let fromDate = new Date();
						fromDate.setUTCHours(+ruleElement.behavior.time_of_observation_from.split(':')[0], +ruleElement.behavior.time_of_observation_from.split(':')[1]);

						let toDate = new Date();
						toDate.setUTCHours(+ruleElement.behavior.time_of_observation_to.split(':')[0], +ruleElement.behavior.time_of_observation_to.split(':')[1]);

						let deviceData = await this.getDeviceData(ruleElement.deviceID, {'from': fromDate, 'periodTo': `1d`} , [['from', fromDate], ['to', toDate]] );

						deviceDataFilterCategory = deviceData.find(item => item.category === ruleElement.behavior.parameter);
					}

					if(
						ruleElement.behavior.temporal_settings === "weekly" && 
						+ruleElement.behavior.time_of_observation_from.split(':')[0]*60 + +ruleElement.behavior.time_of_observation_from.split(':')[1] <= nowDate.getHours()*60 + nowDate.getMinutes() && 
						+ruleElement.behavior.time_of_observation_to.split(':')[0]*60 + +ruleElement.behavior.time_of_observation_to.split(':')[1] >= nowDate.getHours()*60 + nowDate.getMinutes() &&
						dayName === ruleElement.behavior.day_of_the_week
					){
						let fromDate = new Date();
 						fromDate.setDate(fromDate.getDate()-7);
						fromDate.setUTCHours(+ruleElement.behavior.time_of_observation_from.split(':')[0], +ruleElement.behavior.time_of_observation_from.split(':')[1]);

						let toDate = new Date();
						toDate.setUTCHours(+ruleElement.behavior.time_of_observation_to.split(':')[0], +ruleElement.behavior.time_of_observation_to.split(':')[1]);

						let deviceData = await this.getDeviceData(ruleElement.deviceID, { 'from': toDate, 'periodTo': `7d` } , [['from', fromDate], ['to', toDate]] );

						deviceDataFilterCategory = deviceData.find(item => item.category === ruleElement.behavior.parameter);
					}

					if(
						ruleElement.behavior.temporal_settings === "monthly" && 
						+ruleElement.behavior.time_of_observation_from.split(':')[0]*60 + +ruleElement.behavior.time_of_observation_from.split(':')[1] <= nowDate.getHours()*60 + nowDate.getMinutes() && 
						+ruleElement.behavior.time_of_observation_to.split(':')[0]*60 + +ruleElement.behavior.time_of_observation_to.split(':')[1] >= nowDate.getHours()*60 + nowDate.getMinutes() &&
						+nowDate.getDate() === +ruleElement.behavior.day_of_the_month
					){
						let fromDate = new Date();
 						fromDate.setDate(fromDate.getDate()-30);
						fromDate.setUTCHours(+ruleElement.behavior.time_of_observation_from.split(':')[0], +ruleElement.behavior.time_of_observation_from.split(':')[1]);

						let toDate = new Date();
						toDate.setUTCHours(+ruleElement.behavior.time_of_observation_to.split(':')[0], +ruleElement.behavior.time_of_observation_to.split(':')[1]);

						let deviceData = await this.getDeviceData(ruleElement.deviceID, {'from': toDate, 'periodTo': `30d`} , [['from', fromDate], ['to', toDate]] );

						deviceDataFilterCategory = deviceData.find(item => item.category === ruleElement.behavior.parameter);
					}


					if(deviceDataFilterCategory !== null && checkDataValue(ruleElement.behavior, deviceDataFilterCategory.chartData)){
						// console.log('!ALERT! comparisonValue', ruleElement.behavior.temporal_settings, ruleElement.behavior.parameter, ruleElement.behavior.operator, ruleElement.behavior.value);

						const updatedRule = await API.graphql({
							query: updateRules,
							variables: {
								input: {
									id: ruleElement.id,
									status: 'disabled'
								},
							},
						}).then((res) => res.data.updateRules);
						console.log('updatedRule:', updatedRule);

						const alert = await API.graphql({
							query: createAlert,
							variables: {
								input: {
									name: ruleElement.name,
									datetime: new Date().toISOString(),
									description: `${ruleElement.behavior.parameter} ${ruleElement.behavior.operator} ${ruleElement.behavior.value}`,
									deviceID: ruleElement.deviceID,
								}
							}
						}).then(res => res.data.createAlert);

						await this.$store.dispatch('GET_RULES');
						await this.$store.dispatch('GET_ALERTS');
					}
				}
			});

            console.log('rules:', rules, 'devices:', devices);
        },
	
		async getDeviceData(deviceIdAttr, xFarmApiAttrs, centaurApiAttrs) {
			let apiDataName = '';
            let dataFromChart = [];
            let realDeviceData = null;
            let centaurDeviceInfo = null;
            let dataFromxFarm = [];
            let chartsDataArray = [];

            const device = await API.graphql({
                query: getDevice,
                variables: {
                    id: deviceIdAttr,
                },
            }).then((res) => res.data.getDevice);

            if(device.config && device.config.device_id) {
                if(device.category === 'carbon-dioxide' || device.category === 'phosphine') {
                    realDeviceData = await this.$provider.centaurAPI.getRealDeviceData(device.config.device_id, centaurApiAttrs);
                    centaurDeviceInfo = await this.$provider.centaurAPI.getDevicesInfo(device.config.device_id);

                    dataFromChart.push([]);
                    chartsDataArray.push({
                        categoryTitle: this.categories[device.category].title,
                        device: device,
                        category: device.category,
                        chartData: realDeviceData.filter(itemF => itemF.type === this.categories[device.category].category).map(item => {
                            return {
                                "timestamp": item.timestamp,
                                "type": device.category,
                                "value": item.value,
                                "deviceId": item.deviceId,
                                "fullData": null
                            }
                        }),
                    });

                    apiDataName = 'Centaur';
                } else {
					// &from=2019-0-13T14:16:35.471Z&periodTo=1d&limit=null&onlyImages=false&lang=it
                    dataFromxFarm = await this.$provider.xFarm.getDeviceData(device.config.device_id, xFarmApiAttrs);

                    let valueVariableName = null;
					
					if(device.category === 'temperature' || device.category === 'temperature-humidity' || device.category === 'weather-station-xsense'){
						valueVariableName = 'at';
					}
					
					if(device.category === 'leaf-moisture'){
						valueVariableName = 'lw';
					}
					
					if(device.category === 'soil-moisture'){
						valueVariableName = 'sm1';
					}

                    realDeviceData = dataFromxFarm.map(item => {
                        return {
                            "timestamp": item.time,
                            "type": 'temp',
                            "value": item[valueVariableName],
                            "deviceId": item.did,
                            "fullData": item
                        }
                    });

                    apiDataName = 'xFarm';

                    chartsDataArray.push({
                        categoryTitle: this.categories[device.category].title,
                        device: device,
                        category: device.category === 'weather-station-xsense' ? 'temperature' : device.category,
                        chartData: dataFromxFarm.map(item => {
                            return {
                                "timestamp": item.time,
                                "type": 'temp',
                                "value": item[valueVariableName],
                                "deviceId": item.did,
                                "fullData": item
                            }
                        }),
                    });

                    dataFromChart.push([]);

                    if(device.category === 'temperature-humidity' || device.category === 'weather-station-xsense'){
                        let charts = [];

                        if(device.category === 'temperature-humidity'){
                            charts = [
                                ['Dew point temp', 'temperature', 'temp', 'atd'],
                                ['Air humidity', 'ah', 'ah', 'ah'],
                            ]
                        }

                        if(device.category === 'weather-station-xsense'){
                            charts = [
                                ['Dew point temp', 'temperature', 'temp', 'atd'],
                                ['Air humidity', 'ah', 'ah', 'ah'],
                                ['Rain Meter', 'rain-meter', 'mm', 'rm'],
                                ['Average wind speed', 'average-wind-speed', 'aws', 'aws'],
                                ['Max wind speed', 'max-wind-speed', 'mws', 'mws'],
                                ['Solar Radiation', 'solar-radiation', 'W/m2', 'sr'],
                            ]
                        }

                        for (let index = 0; index < charts.length; index++) {
                            dataFromChart.push([]);
                        }

                        for (let index = 0; index < charts.length; index++) {
                            chartsDataArray.push({
                                categoryTitle: charts[index][0],
                                device: device,
                                category: charts[index][1],
                                chartData: dataFromxFarm.map(item => {
                                    return {
                                        "timestamp": item.time,
                                        "type": charts[index][2],
                                        "value": item[charts[index][3]],
                                        "deviceId": item.did,
                                        "fullData": item
                                    }
                                }),
                            });
                        }
                    }
                }
            }

			return chartsDataArray;
        },
	},
	beforeDestroy() {
    	this.unsubscribeAuth();
  	},
}
</script>