import Header from './Header.vue';
import Sidebar from './Sidebar.vue';

import AuthHeader from './AuthHeader.vue';
import Footer from './Footer.vue';

import Tutorial from './Tutorial.vue';

export default {
    Header,
    Sidebar,
    AuthHeader,
    Footer,
    Tutorial
}