<template>
    <div class="categories dropdown-wrapper flex-auto" v-click-outside="closeMe">
        <button 
            type="button" 
            class="btn btn-standart dropdown-btn" 
            :class="[{ active: open }, btnClass, {disabled:disabled}]" 
            @click="toggleMe" :title="getCurrent.name ? getCurrent.name : placeholder ? placeholder : 'Select Option'"
        >
            <span class="active-categories">{{ getCurrent.name ? getCurrent.name : placeholder ? placeholder : 'Select Option' }}</span>
            <span><img src="@/assets/img/Arrow-down.svg"></span>
        </button>

        <div class="categories__dropdown dropdown-content" :class="{active: open}" ref="content">
            <ul v-if="computedOptions.length>0">
                <li v-for="option in computedOptions" :key="option.id" @click="change(option)" :title="option.name">
                    <span>{{ option.name }}</span>
                </li>
            </ul>
            <ul v-else>
                <li @click="closeMe">
                    <span>{{ $translate('no-data') }}</span>
                </li>
            </ul>
        </div>
        <div v-if="open" ref="bottom" class="dropdown__bottom-block" ></div>

    </div>
</template>

<script>
export default {
    name: 'DropdownAdvanced',
    props: ['options', 'current', 'name', 'shadow',"disabled",'placeholder','selectAll'],
	model: {
      	prop: 'current',
  	},
    watch:{
        open(){
            if(this.open){
                this.$nextTick(()=>{
                    this.$refs.bottom.style.top=this.$refs.content.clientHeight+30+'px'
                })
            }
        }
    },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        btnClass() {
            return this.shadow ? 'btn-w-shadow' : 'none-shadow'
        },
        computedOptions(){
            if(this.selectAll){
                return [{id:'all',name:this.selectAll},...this.options]
            }else{
                return this.options
            }
        },
        getCurrent() {
            return this.computedOptions.find(o => o.id === this.current) || {}
        },
    },
    methods: {
        change(option) {
            const event = {
                target: {
                    value: option,
                    name: this.name
                }
            }
            this.$emit('change', event);
			this.$emit('input', option.id);
            this.closeMe();
        },
        closeMe() {
            this.open = false
        },
        toggleMe() {
            this.open = !this.open
            console.log(
            );
        }
    },
    created(){
        if(this.selectAll){
            this.change({id:'all',name:this.selectAll})
        }
       
    },
    mounted(){
       
    }   
}
</script>

<style scoped>
    .disabled{
        pointer-events: none;
        background: #F6F8F9;
    }
  
    
</style>
<style>
    .dropdown__bottom-block{
        height: 30px;
        width: 100%;
        position: absolute;
        opacity:100%;
        pointer-events: none;
    }
    .dropdown-content{
        overflow: auto;
    }
</style>