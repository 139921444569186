import GMap from './GMap'
import PositionStack from './PositionStack';
import Graphql from './Graphql';
import CentaurAPI from './CentaurAPI';
import xFarm from './xFarm';

export default class Provider {
    constructor(store, router) {
        this.store = store;
        this.router = router;

        this.gmap = new GMap();
        this.positionStack = new PositionStack();
        this.graphql = new Graphql(store);
        this.centaurAPI = new CentaurAPI(store);
        this.xFarm = new xFarm(store);
    }
}